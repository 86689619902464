import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Checkbox, Card } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import { Save, Close, Print } from "@material-ui/icons";
import { SaveParty } from '../../Services/PartiesAPI'
import { Row, Col } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { getMuiTheme, getCurrentDate } from '../../Services/Common'
import { SelectItems } from "../../Services/ItemAPI"


const ItemWise_PS_Modal = (props) => {
    const { open, handleClose, Modal_Title, fetchData, GroupID, SelectedGroup, reload, setReload, partyname, phno, email, addr, isactive } = props;
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const [FromDate, setFromDate] = useState(getCurrentDate)
    const [ToDate, setToDate] = useState(getCurrentDate)
    const [ErrorMsg, setErrorMsg] = useState({ NameError: '', PhError: '', EmailError: '', AddressError: '' })
    const [ItemsList, setItemsList] = useState([])
    const [SelectedItem, setSelectedItem] = useState(null)
    useEffect(() => {
        fetchItems()
    }, [open])

    const _handleCLoseModal = async () => {

        handleClose()
        setSelectedItem(null)

    }

    async function fetchItems() {
        const response = await SelectItems(CompID, 0, 1)
        const selectAllItems = { ItemID: 0, ItemName: "Select All" };

        response.data.Data.unshift(selectAllItems);
        if (response.data.Data.length > 0) {
            setSelectedItem(response.data.Data[0])
        }
        setItemsList(response.data.Data)
    }

    return (
        <Dialog
            open={open}
            onClose={_handleCLoseModal}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="ThemeHeader">
                <h style={{ textAlign: "left" }}>{Modal_Title}</h>
            </DialogTitle>
            <DialogContent>
                <Row style={{ marginTop: "15px", fontWeight: "bold" }}>
                    <Col md={6} sm={6} xl={6} lg={6} style={{ fontSize: "16px" }}>
                        <TextField
                            name="FromDate"
                            id="FromDate"
                            label="From Date"
                            type="date"
                            value={FromDate}
                            autoFocus
                            onChange={e => {
                                // fetchData(TrType, SelectedMonth.value, IsDateWise ? 1 : 0, e.target.value, ToDate)
                                setFromDate(e.target.value)
                                // setErrorMsg({ ...ErrorMsg, NameError: '' })
                            }}
                            fullWidth

                        />
                    </Col>
                    <Col md={6} sm={6} xl={6} lg={6}>
                        <TextField
                            name="ToDate"
                            id="ToDate"
                            label="To Date"
                            type="date"
                            value={ToDate}
                            autoFocus
                            onChange={e => {
                                // fetchData(TrType, SelectedMonth.value, IsDateWise ? 1 : 0, FromDate, e.target.value)
                                setToDate(e.target.value)
                                // setErrorMsg({ ...ErrorMsg, NameError: '' })
                            }}
                            fullWidth

                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "15px", fontWeight: "bold" }}>
                    <Autocomplete
                        id="disable-clearable"
                        disableClearable
                        options={ItemsList}
                        getOptionLabel={(option) => option.ItemName}
                        value={SelectedItem}
                        onChange={(e, value) => {
                            // fetchLastPurRate(value.ItemID)
                            setSelectedItem(value)
                            // setRate(parseFloat(value.Rate))
                            // CalculateAmount(Qty, value.Rate)
                            // setErrorMsg({ ...ErrorMsg, ItemError: '' })
                            // setStkQty(value.StockQty)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Select Item" variant="standard" />
                        )}
                        fullWidth
                    />
                </Row>
            </DialogContent>
            <DialogActions>
                <button className="ThemeHeader popup-button" autoFocus
                    onClick={e => {
                        var mtoken = sessionStorage.getItem('token')
                        localStorage.setItem('token', sessionStorage.getItem('token'))
                        var obj = {
                            From_Date: FromDate,
                            To_Date: ToDate,
                            Modal_Title: Modal_Title,
                            SelectedItem
                        }
                        localStorage.setItem("ItemWisePurSale_ReportData", JSON.stringify(obj))
                        window.open("/ItemWisePurSale_Report", "_blank")
                    }}
                >
                    <Print />
                </button>
                <button className="ThemeHeader popup-button" onClick={_handleCLoseModal}><Close /></button>
            </DialogActions>
        </Dialog>
    )
}

export default ItemWise_PS_Modal