import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactDom from 'react-dom';
import { hide_divs } from './../../Services/Common';
import logo from "../../Images/logo/logo.jpeg";

function setPageSize(cssPageSize) {
    const style = document.createElement('style');
    style.innerHTML = `
    @media print {
    
  .page_break_after {
    page-break-after: always;
  }

    @page {
        margin: 10px 2px 2px 2px;
        // margin: 0;
        size: 40mm 30mm;
        padding: 0;

        @page {
            size: landscape;
        }
    }

}    
    

    `;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function InvoiceStickerPrint() {
    // const [Invoice_Num, setInvoice_Num] = useState(null);
    const [Invoice_Date, setInvoice_Date] = useState('');
    const [InvDetailData, setInvDetailData] = useState([]);
    const [SupplierName, setSupplierName] = useState('')

    useEffect(() => {
        hide_divs();
        var _ReportData = JSON.parse(localStorage.getItem('InvoiceStickerPrintData'));
        if (_ReportData) {
            // setInvoice_Num(_ReportData.InvoiceNumber);
            setInvoice_Date(_ReportData.InvoiceDate);
            setInvDetailData(_ReportData.Grid_Data);
            setSupplierName(_ReportData.Party_Name)
            setPageSize('landscape');
        }
        // localStorage.removeItem("InvoiceStickerPrintData")
    }, []);

    function adjustFontSize(length) {
        const textLength = length
        let fontSize;
  
        if (textLength < 10) {
          fontSize = 18; // Large font size for short text
        } else if (textLength < 20) {
          fontSize = 8; // Medium font size for moderate text length
        } else {
          fontSize = 8; // Smaller font size for longer text
        }
  
        return fontSize + "px";
      }
  
      

    const renderStickers = () => {
        let stickers = [];
        let mIndex = 0;

        InvDetailData.forEach((item, index) => {
            const itemName = item.ItemName; // Assuming this field exists in Grid_Data
            const itemQty = item.Qty; // Assuming this field exists in Grid_Data

            // Generate stickers based on the quantity
            for (let i = 0; i < itemQty; i++) {

                const fontSize = adjustFontSize(itemName.length);

                stickers.push(
                    <>
                        {mIndex > 0 && <div className="page_break_after"></div>}

                        <div className='sticker flex flex-column gap-2'>
                            <div className='flex align-items-center'>
                                <div style={{fontSize, padding: '7px 0px'}} className='isp-item-name'>
                                    {itemName}
                                </div>
                                <div className='isp-logo'>
                                    <img src={logo} alt='logo' width={'20px'} height={'20px'} />
                                </div>
                            </div>

                            <div className='flex flex-column width-100 position-relative'>
                            <div className='flex gap-2 width-100'>
                                    <span className='isp-item-label'>Code </span>
                                    <span className='isp-item-value'>: &nbsp; {item.ItemCode}</span>
                                </div>
                                <div className='flex gap-2 width-100'>
                                    <span className='isp-item-label'>Category </span>
                                    <span className='isp-item-value'>: &nbsp; {item.CatName}</span>
                                </div>

                                <div className='flex gap-2 width-100'>
                                    <span className='isp-item-label'>Dept. </span>
                                    <span className='isp-item-value'>: &nbsp; {item.PartyName}</span>
                                </div>

                                <div className='flex gap-2 width-100'>
                                    <span className='isp-item-label'>Supplier </span>
                                    <span className='isp-item-value'>: &nbsp; {SupplierName}</span>
                                </div>

                                <div className='flex gap-2 width-100'>
                                    <span className='isp-item-label'>Inv Date </span>
                                    <span className='isp-item-value'>: &nbsp; {Invoice_Date}</span>
                                </div>
                               

                                {/* <div className='isp-barcode'>
                                    <div style={{ width: "50px"}}>
                                        <Barcode height={20} background='transparent' width={1} margin={10} fontSize={4} value={123} />
                                    </div>

                                </div> */}
                            </div>
                        </div>
                        {/* <Row key={`${itemName}-${i}`} className='sticker' style={{ color: 'black', border: "2px solid black" }}>
                            <Col sm={6} style={{ fontWeight: 'bold' }}>
                                Item Name:
                            </Col>
                            <Col sm={6} style={{ textAlign: "left" }}> {itemName}
                            </Col>
                            <Col sm={6} style={{ fontWeight: 'bold' }}>
                                Dept. :
                            </Col>
                            <Col sm={6} style={{ textAlign: "left" }}> !@#$%&*^&
                            </Col>
                            <Col sm={6} style={{ fontWeight: 'bold' }}>
                                Category :
                            </Col>
                            <Col sm={6} style={{ textAlign: "left" }}> ABCDEFGHJGH
                            </Col>
                            <Col sm={6} style={{ fontWeight: 'bold' }}>
                                Supplier :
                            </Col>
                            <Col sm={6} style={{ textAlign: "left" }}> {SupplierName}
                            </Col>
                            <Col sm={6} style={{ fontWeight: 'bold' }}>
                                Purchase :
                            </Col>
                            <Col sm={6} style={{ textAlign: "left" }}> {Invoice_Date}
                            </Col>
                            <Col sm={6} style={{ fontWeight: 'bold' }}>
                                Item No :
                            </Col>
                            <Col sm={6} style={{ textAlign: "left" }}> {i + 1} of {itemQty}
                            </Col>
                        </Row> */}
                    </>

                );
                mIndex++
            }
        });

        return stickers;
    };

    return ReactDom.createPortal(
        <Row className='report-font' style={{ marginLeft: '1px' }} >
            <Col style={{ display: 'flex', alignItems: 'center' }} lg={12} xl={12} md={12} sm={12} xs={12}>
                <div>{renderStickers()}</div>
            </Col>
        </Row>,
        document.getElementById('body')
    );
}

export default InvoiceStickerPrint;