import {REACT_BASE_URL} from "./Common"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const get_unshipped_orders = async() =>{

    try {
      let token = localStorage.getItem('token')
      var config = {
        method: 'get',
      maxBodyLength: Infinity,
        url: `${REACT_BASE_URL}/squills/Orders/get_unshipped_orders`,
        headers: { 
          'Authorization': 'Bearer '+token
        }
      };

      const response = await axios(config)
      // const response = await axios.get(`${REACT_BASE_URL}/squills/Orders/get_unshipped_orders`, null, { headers: { 'Authorization': "Bearer "+token }}, m);
      
      if(response.data.message === "retrieved successfully")
      {
        return response.data.unshipped_orders;
      }
      else
      {
        return [];
      }
      
    } catch (error) {
      console.log('Error in get_unshippedOrderlist')
    }

}

export const get_unshipped_orders_report = async(Report_Id) =>{

  try {
    const body = JSON.stringify({ReportId: Report_Id})
    let token = localStorage.getItem('token')
    var config = {
      method: 'post',
      data: body,
    maxBodyLength: Infinity,
      url: `${REACT_BASE_URL}/squills/Orders/get_unshipped_orders_report`,
     
      headers: { 
        'Authorization': 'Bearer '+token
      }
    };

    const response = await axios(config)


    // const response = await axios.post(`${REACT_BASE_URL}/squills/Orders/get_unshipped_orders_report`,body, { headers: { 'Authorization': `${token}` } });
  
    let Inprogress = true;
    let reportData = [];
    if(parseInt(response.data.code) === 202)
    {
      Inprogress = true;    
    }
    else if (parseInt(response.data.code) === 200)
    {
      Inprogress = false;
      reportData = response.data.unshipped_orders
    }
  
    return {Inprogress: Inprogress, reportData: reportData}

  } catch (error) {
    console.log('Error in get_unshipped_orders_report')
  }
}

export const create_unshipped_orders_report = async() =>{

  try {
    let token = localStorage.getItem('token')
    var config = {
      method: 'get',
    maxBodyLength: Infinity,
      url: `${REACT_BASE_URL}/squills/Orders/create_unshipped_orders_report`,
      headers: { 
        'Authorization': 'Bearer '+token
      }
    };

    const response = await axios(config)
    // const response = await axios.get(`${REACT_BASE_URL}/squills/Orders/create_unshipped_orders_report`, null, { headers: { 'Authorization': `${token}` } });

    let ReportId = null;
    if(parseInt(response.data.code) === 200)
    {
      if(response.data.message === "report is requested. Use ReportId to fetch the report")
      {
        ReportId = response.data.reportId
      }
    }
  
    return {ReportId : ReportId}
    
  } catch (error) {
    console.log('Error in create_unshipped_orders_report')
  }
}

export const update_unshipped_order_notes = async(data) =>{

  try {
    let token = localStorage.getItem('token')
    let body = JSON.stringify({
      OrderItemID: data.OrderItemId,
      Notes: data.notes
    })
    var config = {
      method: 'post',
    maxBodyLength: Infinity,
      url: `${REACT_BASE_URL}/squills/Orders/update_unshipped_order_notes`,
      data: body,
      headers: { 
        'Authorization': 'Bearer '+token
      }
    };

    const response = await axios(config)
    // const response = await axios.post(`${REACT_BASE_URL}/squills/Orders/update_unshipped_order_notes`, body, { headers: { 'Authorization': `${token}` } });

    toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  
    return response.data.code;
    
  } catch (error) {
    console.log('Error in update_unshipped_order_notes')
  }
}