import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Edit, AccessibilityNew, Add } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux"
import { get_Role_List, get_user, get_users_list, save_user } from './UsersSlice'
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, TextField } from "@material-ui/core"

function UserList() {
    const dispatch = useDispatch();
    document.title = "OE Squills / Users Access List"
    const userList = useSelector(({ UsersSlice }) => UsersSlice.UserList)
    const loading = useSelector(({ UsersSlice }) => UsersSlice.loading)
    const [reload, setReload] = useState(false)
    const [open, setOpen] = useState(false);
    const [type, setType] = useState("")


    useEffect(() => {
        onLoad();
    }, [reload])

    const onLoad = async () => {
        await dispatch(get_users_list())
        await dispatch(get_Role_List())
    }
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const columns = [
        {
            name: "rno",
            label: "#",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "RoleName",
            label: "Role",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                // customBodyRenderLite: (dataIndex, rowIndex) => {
                //     if (userList[dataIndex] != null) {
                //         return (
                //             <Checkbox
                //                 checked={userList[dataIndex].IsAccess == "1" ? true : false}
                //                 disabled
                //                 name="Access"
                //                 color="primary"
                //             />
                //         )
                //     }
                // }
            }
        },
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (userList[dataIndex] != null)
                        return (
                            <>
                                <Link
                                    to={"#"}
                                    onClick={async(e) =>{
                                        setType("Edit")
                                        await dispatch(get_user(userList[dataIndex].UserId))
                                        handleClickOpen()
                                    }}
                                >
                                    <Edit />
                                </Link>
                            </>
                        );
                }
            }
        },
    ];

    return ReactDom.createPortal(

        <div className="col-md-12">
            {/* <ToastContainer /> */}
            <div class="card" >
                <div class="card-body">
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            &nbsp;
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }} >
                            <button
                                type="button"
                                onClick={e => {
                                    setType("Add")
                                    handleClickOpen()
                                }}
                                className="btn btn-primary waves-effect height-of-button"
                            > <Add /> Add New </button>
                        </div>
                    </div>
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            style={{ size: "200px" }}
                            data={userList}
                            columns={columns}
                            options={{
                                selectableRows: false,
                                search: false,
                                download: false,
                                print: false,
                                viewColumns: false,
                                filter: false,
                                selectableRows: false,
                                selection: true,
                                toolbar: false,
                                paging: false,
                                headerStyle: {
                                    fontSize: 12
                                },
                            }
                            }
                        />
                    </MuiThemeProvider>

                    <GlobalLoader
                        open={loading}
                    />
                </div>
            </div>
            <UserDialog 
                open={open}
                handleClose={handleClose}
                dispatch={dispatch}
                reload={reload}
                setReload={setReload}
                type={type}
                
            />
        </div>

        , document.getElementById('content')
    )
}

const UserDialog = (props) => {
    const { open, handleClose, dispatch, reload, setReload, type } = props;
    const User = useSelector(({ UsersSlice }) => UsersSlice.User)
    const userRolesList = useSelector(({ UsersSlice }) => UsersSlice.userRolesList)
    const [formData, setFormData] = useState({
        UserId: "0",
        Name: '',
        Username: '',
        Password: '',
        RoleId: null,
        IsActive: false
    })
    const [error, setError] = useState({
        Name: '',
        Username: '',
        Password: '',
        RoleId: null,
    })

    useEffect(() => {
      if(type == "Edit"){
        if(User){
        setFormData({
            UserId: User.UserId,
           Name: User.Name,
           Username: User.Username,
           Password: User.Password,
           RoleId: userRolesList.find(x => x.RoleId == User.RoleId),
           IsActive: User.IsActive == "0" ? false : true
       })
      }
    }
    }, [User])
    

 

    const handleSubmit = async () => {
        if(validation()){
            let params ={
                Username: formData.Username,
                Name: formData.Name,
                Password: formData.Password,
                RoleId: formData.RoleId.RoleId,
                IsActive: formData.IsActive ? "1" : "0",
                UserId: formData.UserId
            }
            const response = await dispatch(save_user(params))
            toast.success(response.payload.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });    
            handleClose()
            setReload(!reload)
        }

    }

    const validation = () =>{
        let errors ={
            Name: '',
        Username: '',
        Password: '',
        RoleId: null,
        }

        if((formData.Name).trim() == ""){
            errors.Name = "Name is required"
        }

        if((formData.Username).trim() == ""){
            errors.Username = "Username is required"
        }

        if((formData.Password).trim() == ""){
            errors.Password = "Password is required"
        }

        if(!formData.RoleId){
            errors.RoleId = "Role is required"
        }

        if((formData.Name).trim() == "" || (formData.Username).trim() == "" || (formData.Password).trim() == "" || !formData.RoleId){
            setError(errors)
            return false
        }
        else{
            setError(errors)
            return true
        }
    }

    const clear = ()=>{
        setFormData({
            UserId: "0",
            Name: '',
            Username: '',
            Password: '',
            RoleId: null,
            IsActive: false
        })
        setError({
            Name: '',
            Username: '',
            Password: '',
            RoleId: null,
        })
        handleClose()
    }

    return (
        <Dialog
            open={open}
            onClose={clear}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {
                    type != "Add" ? (
                        "Edit User"
                    ) : (
                        "Add New User"
                    )
                }
            </DialogTitle>
            <ToastContainer />
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <TextField
                                name="name"
                                id="name"
                                label="Name"
                                value={formData.Name}
                                fullWidth
                                onChange={e => setFormData({ ...formData, Name: e.target.value })}
                            />
                            <span style={{color:'red'}} >{error.Name}</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" >
                            <Autocomplete
                                options= {userRolesList}
                                value={formData.RoleId}
                                getOptionLabel= {(option) => option.RoleName}
                                id="disable-close-on-select"
                                onChange={(event, newValue) => setFormData({...formData, RoleId: newValue })}
                                renderInput={(params) => (
                                    <TextField {...params} label="Role" variant="standard" />
                                )}
                            />
                             <span style={{color:'red'}} >{error.RoleId}</span>
                        </div>
                    </div>
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <TextField
                                name="username"
                                id="username"
                                label="user name"
                                value={formData.Username}
                                fullWidth
                                onChange={e => setFormData({ ...formData, Username: e.target.value })}
                            />
                             <span style={{color:'red'}} >{error.Username}</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" >
                            <TextField
                                name="password"
                                type="password"
                                id="password"
                                label="Password"
                                value={formData.Password}
                                fullWidth
                                onChange={e => setFormData({ ...formData, Password: e.target.value })}
                            />
                             <span style={{color:'red'}} >{error.Password}</span>
                        </div>
                    </div>
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <Checkbox
                                checked={formData.IsActive ? true : false}
                                onChange={e => setFormData({...formData, IsActive: e.target.checked })}
                                name="Access"
                                color="primary"
                            /> Active
                        </div>
                        
                    </div>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={clear}>Close</Button>
                <Button onClick={handleSubmit} autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default UserList