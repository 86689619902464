import React, {useState} from 'react'
import clsx from 'clsx'
import DeleteIcon from '@material-ui/icons/Delete'
import GlobalFilter from './GlobalFilter'
import IconButton from '@material-ui/core/IconButton'
import { lighten, makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { Button, Grid, CircularProgress } from "@material-ui/core"
import { create_unshipped_orders_report, get_unshipped_orders_report } from "../../Services/Orders"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}))

const TableToolbar = props => {


  const classes = useToolbarStyles()
  const {
    numSelected,
    addUserHandler,
    deleteUserHandler,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    setData,
    Report_Id, setReport_Id
  } = props
  const [buttonDisabled, setButtonDisabled] = useState(false)


  const handleClickForFetch = async() =>{
    
    setButtonDisabled(true)
    const response = await create_unshipped_orders_report();
    getUnshippedOrdersReport(response.ReportId);
    setReport_Id(response.ReportId)
  }
  

  const Timer = (ReportId) =>{
    const _Typography = document.getElementById('forReport');
    if(_Typography){
      var timeleft = 20;
      var downloadTimer = setInterval(function(){
        if(timeleft <= 0){
          timeleft -= 1;
          _Typography.innerHTML = ""
          clearInterval(downloadTimer);
          getUnshippedOrdersReport(ReportId)
          
        } else {
          _Typography.innerHTML = `Waiting ${timeleft} seconds for report. (Current Status): INPROGRESS`;
          timeleft -= 1;
        }
        
      }, 1000);
    }
  }

  const getUnshippedOrdersReport = async (ReportId) =>{

    const response = await get_unshipped_orders_report(ReportId);

    if(response.Inprogress)
    {
      Timer(ReportId)
    }
    else
    {
      toast.success('Report Fetch Successful...', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

      setData(response.reportData)
      setButtonDisabled(false)
    }

  }


  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
       {/* <ToastContainer /> */}
      {/* {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : ( */}
        <Typography className={classes.title} id="tableTitle">
          <Grid container spacing={2} >
              <Grid sm={3} >
                <Button onClick={handleClickForFetch} disabled={buttonDisabled} variant="outlined" >
                  {
                    buttonDisabled ? (<>
                    Loading...  <CircularProgress size={30} />
                    </>
                    )
                    :
                    (
                      <>
                      Fetch Report
                      </>
                    )
                  }
                  
                  
                </Button>
              </Grid>
              <Grid sm={9} >
                <Typography id="forReport" style={{paddingTop: '10px'}} > </Typography>
              </Grid>
          </Grid>
        </Typography>
      {/* )
      } */}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={deleteUserHandler}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  addUserHandler: PropTypes.func.isRequired,
  deleteUserHandler: PropTypes.func.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired,
}

export default TableToolbar
