import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import { Card, Checkbox } from '@material-ui/core'
import { Save, Cancel } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { AccessibilityNew } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux"
import { get_Role_List, get_role_modules, get_role_screens, save_role_modules, save_role_screens } from './UsersSlice'
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/system';


function UserScreen() {
    const dispatch = useDispatch();
    const _UserScreenList = useSelector(({ UsersSlice }) => UsersSlice.UserScreenList)
    const loading = useSelector(({ UsersSlice }) => UsersSlice.loading)
    const Role = useSelector(({ UsersSlice }) => UsersSlice.Role)
    const Module = useSelector(({ UsersSlice }) => UsersSlice.Module)
    let [UserScreenList, setUserScreenList] = useState(_UserScreenList)
    const [btnLoader, setBtnLoader] = useState(false)
    const [open, setOpen] = useState(false);
    useEffect(() => {
        onLoad();
    }, [Role])

    useEffect(() => {
        setUserScreenList(_UserScreenList)
    }, [_UserScreenList])

    const onLoad = async () => {
        if (Role.RoleId) {
            await dispatch(get_role_screens({ RoleId: Role.RoleId, ModuleId: Module.ModuleId }))
        }
    }

    document.title = "OE Squills / Users Screen"


    const handleSubmit = async () => {
        setBtnLoader(true)
        const response = await dispatch(save_role_screens(UserScreenList))

        toast.success(response.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        setBtnLoader(false)
        onLoad();
    }

    const StyledTable = styled(Table)({
        fontSize: 20,
      });

    return ReactDom.createPortal(

        <>
            {/* <ToastContainer /> */}
            <Card >
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <h2>{Role.RoleName} / {Module.ModuleName}</h2>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }} >
                            <button
                                type="button"
                                onClick={handleSubmit}
                                disabled={btnLoader}
                                className="btn btn-primary waves-effect height-of-button"
                            > <Save />{btnLoader ? "Saving..." : "Save"} </button> &ensp;
                            <Link to={"/UserAccess"} id="cancel" className="btn btn-primary waves-effect height-of-button" ><Cancel /> Close </Link>
                        </div>
                    </div>
                    <StyledTable>
                        <Table style={{fontSize: '20px'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Screen Name</TableCell>
                                    <TableCell>Access</TableCell>
                                    <TableCell>Add</TableCell>
                                    <TableCell>Edit</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {UserScreenList.map((row, rowIndex) => (
                                    <>
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                            <TableCell>
                                                {
                                                    row.Controls.length > 0 && (
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => {

                                                                let newModuleAccessData = [...UserScreenList]
                                                                // newModuleAccessData[dataIndex].IsAccess = e.target.checked ? "1" : "0"
                                                                newModuleAccessData[rowIndex] = Object.assign({}, newModuleAccessData[rowIndex], { open: !row.open })
                                                                setUserScreenList(newModuleAccessData)
                                                            }}
                                                        >
                                                            {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    )
                                                }

                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.ScreenName}
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={row.IsAccess == "1" ? true : false}
                                                    onChange={e => {
                                                        let newModuleAccessData = [...UserScreenList]
                                                        // newModuleAccessData[dataIndex].IsAccess = e.target.checked ? "1" : "0"
                                                        newModuleAccessData[rowIndex] = Object.assign({}, newModuleAccessData[rowIndex], { IsAccess: e.target.checked ? "1" : "0" })
                                                        setUserScreenList(newModuleAccessData)
                                                    }}
                                                    name="Access"
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={row.AddNew == "1" ? true : false}
                                                    disabled={row.IsAccess == "1" ? false : true}
                                                    onChange={e => {
                                                        let newModuleAccessData = [...UserScreenList]
                                                        // newModuleAccessData[dataIndex].IsAccess = e.target.checked ? "1" : "0"
                                                        newModuleAccessData[rowIndex] = Object.assign({}, newModuleAccessData[rowIndex], { AddNew: e.target.checked ? "1" : "0" })
                                                        setUserScreenList(newModuleAccessData)
                                                    }}
                                                    name="Access"
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={row.Modify == "1" ? true : false}
                                                    disabled={row.IsAccess == "1" ? false : true}
                                                    onChange={e => {
                                                        let newModuleAccessData = [...UserScreenList]
                                                        // newModuleAccessData[dataIndex].IsAccess = e.target.checked ? "1" : "0"
                                                        newModuleAccessData[rowIndex] = Object.assign({}, newModuleAccessData[rowIndex], { Modify: e.target.checked ? "1" : "0" })
                                                        setUserScreenList(newModuleAccessData)
                                                    }}
                                                    name="Access"
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={row.Del == "1" ? true : false}
                                                    onChange={e => {
                                                        let newModuleAccessData = [...UserScreenList]
                                                        // newModuleAccessData[dataIndex].IsAccess = e.target.checked ? "1" : "0"
                                                        newModuleAccessData[rowIndex] = Object.assign({}, newModuleAccessData[rowIndex], { Del: e.target.checked ? "1" : "0" })
                                                        setUserScreenList(newModuleAccessData)
                                                    }}
                                                    disabled={row.IsAccess == "1" ? false : true}
                                                    name="Access"
                                                    color="primary"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                <Collapse in={row.open} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin: 1 }}>
                                                        <Typography variant="h6" gutterBottom component="div">
                                                            {row.ScreenName}
                                                        </Typography>
                                                        <Table size="small" aria-label="purchases">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Screen Name</TableCell>
                                                                    <TableCell>Access</TableCell>
                                                                    <TableCell>Add</TableCell>
                                                                    <TableCell>Edit</TableCell>
                                                                    <TableCell>Delete</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.Controls.map((controlRow, controlIndex) => (
                                                                    <TableRow key={controlIndex}>
                                                                        <TableCell component="th" scope="row">
                                                                            {controlRow.ScreenName}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={controlRow.IsAccess == "1" ? true : false}
                                                                                onChange={e => {
                                                                                    let array = [];
                                                                                    let newModuleAccessData = [...UserScreenList[rowIndex].Controls]
                                                                                    newModuleAccessData[controlIndex] = Object.assign({}, newModuleAccessData[controlIndex], { IsAccess: e.target.checked ? "1" : "0" })
                                                                                    UserScreenList.map((screen, _screenIndex) =>{
                                                                                        array.push({
                                                                                            RoleScreenId: screen.RoleScreenId,
                                                                                            ScreenId: screen.ScreenId,
                                                                                            open: screen.open,
                                                                                            ScreenName: screen.ScreenName,
                                                                                            IsAccess:   screen.IsAccess,
                                                                                            AddNew: screen.AddNew,
                                                                                            Del: screen.Del,
                                                                                            Modify: screen.Modify,
                                                                                            Controls: []
                                                                                        });
                                                                                        screen.Controls.map((control, _controlIndex) => {
                                                                                            if(control.ScreenId == newModuleAccessData[controlIndex].ScreenId){
                                                                                                array[_screenIndex].Controls.push(newModuleAccessData[controlIndex])
                                                                                            }else{
                                                                                                array[_screenIndex].Controls.push(control)
                                                                                            }
                                                                                        })
                                                                                    })

                                                                                    setUserScreenList(array)
                                                                                }}
                                                                                name="Access"
                                                                                color="primary"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={controlRow.AddNew == "1" ? true : false}
                                                                                disabled={controlRow.IsAccess == "1" ? false : true}
                                                                                onChange={e => {
                                                                                    let array = [];
                                                                                    let newModuleAccessData = [...UserScreenList[rowIndex].Controls]
                                                                                    newModuleAccessData[controlIndex] = Object.assign({}, newModuleAccessData[controlIndex], { AddNew: e.target.checked ? "1" : "0" })
                                                                                    UserScreenList.map((screen, _screenIndex) =>{
                                                                                        array.push({
                                                                                            RoleScreenId: screen.RoleScreenId,
                                                                                            ScreenId: screen.ScreenId,
                                                                                            open: screen.open,
                                                                                            ScreenName: screen.ScreenName,
                                                                                            IsAccess:   screen.IsAccess,
                                                                                            AddNew: screen.AddNew,
                                                                                            Del: screen.Del,
                                                                                            Modify: screen.Modify,
                                                                                            Controls: []
                                                                                        });
                                                                                        screen.Controls.map((control, _controlIndex) => {
                                                                                            if(control.ScreenId == newModuleAccessData[controlIndex].ScreenId){
                                                                                                array[_screenIndex].Controls.push(newModuleAccessData[controlIndex])
                                                                                            }else{
                                                                                                array[_screenIndex].Controls.push(control)
                                                                                            }
                                                                                        })
                                                                                    })

                                                                                    setUserScreenList(array)
                                                                                }}
                                                                                name="Access"
                                                                                color="primary"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={controlRow.Modify == "1" ? true : false}
                                                                                disabled={controlRow.IsAccess == "1" ? false : true}
                                                                                onChange={e => {

                                                                                    let array = [];
                                                                                    let newModuleAccessData = [...UserScreenList[rowIndex].Controls]
                                                                                    newModuleAccessData[controlIndex] = Object.assign({}, newModuleAccessData[controlIndex], { Modify: e.target.checked ? "1" : "0" })
                                                                                    UserScreenList.map((screen, _screenIndex) =>{
                                                                                        array.push({
                                                                                            RoleScreenId: screen.RoleScreenId,
                                                                                            ScreenId: screen.ScreenId,
                                                                                            open: screen.open,
                                                                                            ScreenName: screen.ScreenName,
                                                                                            IsAccess:   screen.IsAccess,
                                                                                            AddNew: screen.AddNew,
                                                                                            Del: screen.Del,
                                                                                            Modify: screen.Modify,
                                                                                            Controls: []
                                                                                        });
                                                                                        screen.Controls.map((control, _controlIndex) => {
                                                                                            if(control.ScreenId == newModuleAccessData[controlIndex].ScreenId){
                                                                                                array[_screenIndex].Controls.push(newModuleAccessData[controlIndex])
                                                                                            }else{
                                                                                                array[_screenIndex].Controls.push(control)
                                                                                            }
                                                                                        })
                                                                                    })

                                                                                    setUserScreenList(array)
                                                                                }}
                                                                                name="Access"
                                                                                color="primary"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={controlRow.Del == "1" ? true : false}
                                                                                disabled={controlRow.IsAccess == "1" ? false : true}
                                                                                onChange={e => {
                                                                                    let array = [];
                                                                                    let newModuleAccessData = [...UserScreenList[rowIndex].Controls]
                                                                                    newModuleAccessData[controlIndex] = Object.assign({}, newModuleAccessData[controlIndex], { Del: e.target.checked ? "1" : "0" })
                                                                                    UserScreenList.map((screen, _screenIndex) =>{
                                                                                        array.push({
                                                                                            RoleScreenId: screen.RoleScreenId,
                                                                                            ScreenId: screen.ScreenId,
                                                                                            open: screen.open,
                                                                                            ScreenName: screen.ScreenName,
                                                                                            IsAccess:   screen.IsAccess,
                                                                                            AddNew: screen.AddNew,
                                                                                            Del: screen.Del,
                                                                                            Modify: screen.Modify,
                                                                                            Controls: []
                                                                                        });
                                                                                        screen.Controls.map((control, _controlIndex) => {
                                                                                            if(control.ScreenId == newModuleAccessData[controlIndex].ScreenId){
                                                                                                array[_screenIndex].Controls.push(newModuleAccessData[controlIndex])
                                                                                            }else{
                                                                                                array[_screenIndex].Controls.push(control)
                                                                                            }
                                                                                        })
                                                                                    })

                                                                                    setUserScreenList(array)

                                                                                   
                                                                                }}
                                                                                name="Access"
                                                                                color="primary"
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </StyledTable>

                    <GlobalLoader
                        open={loading}
                    />
                </Card>
        </>

        , document.getElementById('content')
    )
}

export default UserScreen