import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLoggedOut } from '../Login/LoginSlice'
import malePlaceholderImage from "../../Images/male-placeholder-image.jpeg"
import LogoutIcon from '@mui/icons-material/Logout';
function Topbar() {
  const dispatch = useDispatch()
  const GlobalFormName = useSelector(({ UsersSlice }) => UsersSlice.GlobalFormName)
  const UserName = useSelector(({ login }) => login?.user[0]?.UserName)
  const UserType = useSelector(({ login }) => login?.user[0]?.UserType)
  const handleLogOut = () => {
    dispatch(userLoggedOut())
  }
  return ReactDOM.createPortal(
    <>
      <a className="sidebar-toggle" style={{ paddingBottom: "5px" }}>
        <i className="hamburger align-self-center" />
      </a>
      <form className="d-none d-sm-inline-block">
        <div style={{}}>
          <p>
            <h3
            style={{ paddingTop: "10px" }}
            // onClick={e => {
            //   showModal();
            // }}
            >{UserName} / {GlobalFormName}</h3>
          </p>
        </div>
        {/* <div className="input-group input-group-navbar">
          <input type="text" className="form-control" placeholder="Search projects…" aria-label="Search" />
          <button className="btn" type="button">
            <i className="align-middle" data-feather="search" />
          </button>
        </div> */}
      </form>
      {/* <ul className="navbar-nav">
        <li className="nav-item px-2 dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="servicesDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Mega menu
          </a>
          <div className="dropdown-menu dropdown-menu-start dropdown-mega" aria-labelledby="servicesDropdown">
            <div className="d-md-flex align-items-start justify-content-start">
              <div className="dropdown-mega-list">
                <div className="dropdown-header">UI Elements</div>
                <a className="dropdown-item" href="#">Alerts</a>
                <a className="dropdown-item" href="#">Buttons</a>
                <a className="dropdown-item" href="#">Cards</a>
                <a className="dropdown-item" href="#">Carousel</a>
                <a className="dropdown-item" href="#">General</a>
                <a className="dropdown-item" href="#">Grid</a>
                <a className="dropdown-item" href="#">Modals</a>
                <a className="dropdown-item" href="#">Tabs</a>
                <a className="dropdown-item" href="#">Typography</a>
              </div>
              <div className="dropdown-mega-list">
                <div className="dropdown-header">Forms</div>
                <a className="dropdown-item" href="#">Layouts</a>
                <a className="dropdown-item" href="#">Basic Inputs</a>
                <a className="dropdown-item" href="#">Input Groups</a>
                <a className="dropdown-item" href="#">Advanced Inputs</a>
                <a className="dropdown-item" href="#">Editors</a>
                <a className="dropdown-item" href="#">Validation</a>
                <a className="dropdown-item" href="#">Wizard</a>
              </div>
              <div className="dropdown-mega-list">
                <div className="dropdown-header">Tables</div>
                <a className="dropdown-item" href="#">Basic Tables</a>
                <a className="dropdown-item" href="#">Responsive Table</a>
                <a className="dropdown-item" href="#">Table with Buttons</a>
                <a className="dropdown-item" href="#">Column Search</a>
                <a className="dropdown-item" href="#">Muulti Selection</a>
                <a className="dropdown-item" href="#">Ajax Sourced Data</a>
              </div>
            </div>
          </div>
        </li>
      </ul> */}

      <div className="navbar-collapse collapse">
        <ul className="navbar-nav navbar-align">
          {/* <li className="nav-item dropdown">
            <a className="nav-icon dropdown-toggle" href="#" id="messagesDropdown" data-bs-toggle="dropdown">
              <div className="position-relative">
                <i className="align-middle" data-feather="message-circle" />
                <span className="indicator">4</span>
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="messagesDropdown">
              <div className="dropdown-menu-header">
                <div className="position-relative">
                  4 New Messages
                </div>
              </div>
              <div className="list-group">
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <img src="img/avatars/avatar-5.jpg" className="avatar img-fluid rounded-circle" alt="Ashley Briggs" />
                    </div>
                    <div className="col-10 ps-2">
                      <div className="text-dark">Ashley Briggs</div>
                      <div className="text-muted small mt-1">Nam pretium turpis et arcu. Duis arcu tortor.</div>
                      <div className="text-muted small mt-1">15m ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <img src="img/avatars/avatar-2.jpg" className="avatar img-fluid rounded-circle" alt="Carl Jenkins" />
                    </div>
                    <div className="col-10 ps-2">
                      <div className="text-dark">Carl Jenkins</div>
                      <div className="text-muted small mt-1">Curabitur ligula sapien euismod vitae.</div>
                      <div className="text-muted small mt-1">2h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <img src="img/avatars/avatar-4.jpg" className="avatar img-fluid rounded-circle" alt="Stacie Hall" />
                    </div>
                    <div className="col-10 ps-2">
                      <div className="text-dark">Stacie Hall</div>
                      <div className="text-muted small mt-1">Pellentesque auctor neque nec urna.</div>
                      <div className="text-muted small mt-1">4h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <img src="img/avatars/avatar-3.jpg" className="avatar img-fluid rounded-circle" alt="Bertha Martin" />
                    </div>
                    <div className="col-10 ps-2">
                      <div className="text-dark">Bertha Martin</div>
                      <div className="text-muted small mt-1">Aenean tellus metus, bibendum sed, posuere ac, mattis non.</div>
                      <div className="text-muted small mt-1">5h ago</div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="dropdown-menu-footer">
                <a href="#" className="text-muted">Show all messages</a>
              </div>
            </div>
          </li> */}
          {/* <li className="nav-item dropdown">
            <a className="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown">
              <div className="position-relative">
                <i className="align-middle" data-feather="bell-off" />
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="alertsDropdown">
              <div className="dropdown-menu-header">
                4 New Notifications
              </div>
              <div className="list-group">
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <i className="text-danger" data-feather="alert-circle" />
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Update completed</div>
                      <div className="text-muted small mt-1">Restart server 12 to complete the update.</div>
                      <div className="text-muted small mt-1">2h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <i className="text-warning" data-feather="bell" />
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Lorem ipsum</div>
                      <div className="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
                      <div className="text-muted small mt-1">6h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <i className="text-primary" data-feather="home" />
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Login from 192.186.1.1</div>
                      <div className="text-muted small mt-1">8h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <i className="text-success" data-feather="user-plus" />
                    </div>
                    <div className="col-10">
                      <div className="text-dark">New connection</div>
                      <div className="text-muted small mt-1">Anna accepted your request.</div>
                      <div className="text-muted small mt-1">12h ago</div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="dropdown-menu-footer">
                <a href="#" className="text-muted">Show all notifications</a>
              </div>
            </div>
          </li> */}
          {/* <li className="nav-item dropdown">
            <a className="nav-flag dropdown-toggle" href="#" id="languageDropdown" data-bs-toggle="dropdown">
              <img src="img/flags/us.png" alt="English" />
            </a>
            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="languageDropdown">
              <a className="dropdown-item" href="#">
                <img src="img/flags/us.png" alt="English" width={20} className="align-middle me-1" />
                <span className="align-middle">English</span>
              </a>
              <a className="dropdown-item" href="#">
                <img src="img/flags/es.png" alt="Spanish" width={20} className="align-middle me-1" />
                <span className="align-middle">Spanish</span>
              </a>
              <a className="dropdown-item" href="#">
                <img src="img/flags/de.png" alt="German" width={20} className="align-middle me-1" />
                <span className="align-middle">German</span>
              </a>
              <a className="dropdown-item" href="#">
                <img src="img/flags/nl.png" alt="Dutch" width={20} className="align-middle me-1" />
                <span className="align-middle">Dutch</span>
              </a>
            </div>
          </li> */}
          <li className="nav-item dropdown">
            {/* <a className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-bs-toggle="dropdown">
              <i className="align-middle" data-feather="settings" />
            </a>
            <a className="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-bs-toggle="dropdown">
              <img src={malePlaceholderImage} width={100} height={100} className="avatar img-fluid rounded-circle me-1" alt="Chris Wood" /> <span className="text-dark">{user[0]?.Name}</span>
            </a> */}

            {/* <div className="dropdown-menu dropdown-menu-end">
              <a className="dropdown-item" href="pages-profile.html"><i className="align-middle me-1" data-feather="user" /> Profile</a>
              <a className="dropdown-item" href="#"><i className="align-middle me-1" data-feather="pie-chart" /> Analytics</a>
              <div className="dropdown-divider" />
              <a className="dropdown-item" href="pages-settings.html">Settings &amp; Privacy</a>
              <a className="dropdown-item" href="#">Help</a>
              <Link className="dropdown-item" to="/" onClick={handleLogOut}>Sign out</Link>
            </div> */}
          </li>
          <Link
            to="/"
            title="Log Out"
            onClick={e => {
              handleLogOut()
            }}
          >
            <LogoutIcon style={{ color: "#293042", marginRight: "8px" }} className="popup-label" />
          </Link>
        </ul>
      </div>

    </>, document.getElementById('topbar')

  )
}

export default Topbar