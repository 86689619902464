import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from "react-redux"
import { hide_divs, getCurrentDate } from "./../../Services/Common"
import ReactDom from "react-dom"
import { useSelector, useDispatch } from "react-redux"

function setPageSize(cssPageSize) {

    const style = document.createElement('style');
    style.innerHTML = `@page {

    margin-top: 2%;
    margin-bottom: 2%;

};
    `;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function InvoiceReportPrint() {
    const UserName = useSelector(({ login }) => login?.user[0]?.UserName)
    const CompName = useSelector(({ login }) => login.UserCompany[0]?.CompName)
    const Address = useSelector(({ login }) => login.UserCompany[0]?.Address)
    const [CompanyName, setCompanyName] = useState('')
    const [CompanyAddress, setCompanyAddress] = useState('')
    const [Invoice_Num, setInvoice_Num] = useState(null)
    const [Invoice_Date, setInvoice_Date] = useState('')
    const [Party_Name, setParty_Name] = useState('')
    const [PartyContact, setPartyContact] = useState('')
    const [InvDetailData, setInvDetailData] = useState([])
    const [TotalsQty, setTotalsQty] = useState(0)
    const [TotalsAmount, setTotalsAmount] = useState(0)
    const [Form_Name, setForm_Name] = useState("")

    useEffect(() => {
        hide_divs()

        var _ReportData = JSON.parse(localStorage.getItem("InvoiceReportData"))

        if (_ReportData) {
            setForm_Name(_ReportData.FormName)
            setCompanyName(_ReportData.Comp_Name)
            setCompanyAddress(_ReportData.Comp_Add)
            setInvoice_Num(_ReportData.InvoiceNumber)
            setInvoice_Date(_ReportData.InvoiceDate)
            setParty_Name(_ReportData.Party_Name)
            setInvDetailData(_ReportData.Grid_Data)
            setPartyContact(_ReportData.PartyPhoenNumber)
            setTotalsQty(_ReportData.TotalQuantity)
            setTotalsAmount(_ReportData.TtlAmt)
            setPageSize('Portrait')
        }
        localStorage.removeItem("InvoiceReportData")
    }, [])
    return ReactDom.createPortal(
        <Row className='report-font' style={{ fontSize: '18px' }}>
            <Col>
                <div  >
                    <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black", marginTop: '3%' }}>
                        <Col sm={12} md={12} xl={12}>
                            <h1 style={{ fontWeight: "bold" }}> {CompName} </h1>
                            <h3> {Form_Name == "Purchase" ? "Purchase Invoice" : "Consumption Invoice"} </h3>
                            {/* {Address} */}
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '2%', color: "black", marginLeft: '1.5%' }}  >
                        <Col sm={2} md={2} xl={2} style={{ fontWeight: "bold" }}>
                            <h3><b>Inv#:</b></h3>
                        </Col>
                        <Col sm={4} md={4} xl={4} style={{ paddingLeft: "40px" }} >
                            {Invoice_Num}
                        </Col>
                        <Col sm={3} md={3} xl={3} style={{ textAlign: "right" }}>
                            <b>{Form_Name == "Purchase" ? "Party Name:" : "Department Name:"}</b>
                        </Col>
                        <Col sm={3} md={3} xl={3}  >
                            {Party_Name}
                        </Col>

                    </Row>
                    <Row style={{ color: "black", marginLeft: '1.5%' }}  >
                        <Col sm={2} md={2} xl={2} >
                            <b>Inv Date:</b>
                        </Col>
                        <Col sm={4} md={4} xl={4} style={{ paddingLeft: "40px" }} >
                            {Invoice_Date}
                        </Col>
                        <Col sm={3} md={3} xl={3} style={{ textAlign: "right" }}>
                            <b>Party Contact:</b>
                        </Col>
                        <Col sm={3} md={3} xl={3} >
                            {PartyContact}
                        </Col>

                    </Row>


                    <Row className='row-margin-left row-margin-right' style={{ marginTop: '2%' }}>
                        <Col>
                            <div id='table_div' className="row-margin-top" >
                                <table id='report_table' className='report_table' width="95%" style={{ marginLeft: "2%" }}>
                                    <thead id="thead" style={{ borderBottom: '2px solid black', borderTop: '2px solid black', color: "black" }}>
                                        <tr style={{ border: "none" }}>
                                            {/*  */}
                                            {/* id='vno_td' */}
                                            <th style={{ width: '12%', textAlign: 'left', fontWeight: "bold" }} >Sr#</th>
                                            {/* id='type_td' */}
                                            <th style={{ width: '18%', textAlign: 'center', fontWeight: "bold" }} >Item Desc</th>

                                            <th style={{ width: '12%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                                            {/* id='balance_td' */}
                                            <th style={{ width: '12%', textAlign: 'right', fontWeight: "bold" }} >Rate</th>
                                            <th style={{ width: '12%', textAlign: 'right', fontWeight: "bold" }} >Amount</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            InvDetailData.map((value, index) => {

                                                return (
                                                    <tr >
                                                        <td style={{ textAlign: 'left' }}>{value.SrNo}</td>
                                                        <td style={{ textAlign: 'center' }} >{value.ItemName}</td>
                                                        <td style={{ textAlign: 'center' }} >{value.Qty}</td>
                                                        <td style={{ textAlign: 'right' }} >{value.Rate}</td>
                                                        <td style={{ textAlign: 'right' }} >{value.Amount}</td>

                                                    </tr>
                                                )
                                            })
                                        }


                                        <tr style={{ fontWeight: 'bold', borderTop: '2px solid black', borderBottom: '2px solid black' }}>
                                            <td colSpan={2} style={{ textAlign: 'right' }}> Totals :</td>

                                            <td style={{ textAlign: 'center' }}>{TotalsQty}</td>
                                            <td></td>
                                            <td style={{ textAlign: 'right' }} >{TotalsAmount.toFixed(2)}</td>


                                        </tr>
                                    </tbody>

                                </table>
                                <div >

                                    <div style={{ marginTop: '5%', marginLeft: "1.5%" }}>
                                        &nbsp;

                                        Username: {UserName}   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        Date: {`${getCurrentDate()}`}
                                    </div>

                                </div>


                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>

        </Row>, document.getElementById('body')
    )
}

export default InvoiceReportPrint