import React, { useEffect, useState } from 'react'
import ReactDom from "react-dom"
import { useSelector, useDispatch } from 'react-redux';
import { hide_divs } from '../../Services/Common'
import img from "../../round-profile-picture-png-transparent-png-transparent-png-image-pngitem.png"
import { loggedIn } from "./LoginSlice"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, Link } from "react-router-dom"
import { CircularProgress, Box } from "@material-ui/core"
import BackGroundPic from "../../Images/agv-transporting-concrete-wall-flooring.webp"
import { Row, Col } from "react-bootstrap"
import './WelcomeAnimation.css'

function Login() {
    const history = useHistory()
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    })

    useEffect(() => {
        hide_divs()
    }, [])


    const setToken = (token) => {
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token')
        }
        localStorage.setItem('token', token)
    }

    const handleSubmit = async (event) => {
        debugger
        event.preventDefault()
        setLoader(true)
        const response = await dispatch(loggedIn(formData))

        if (!response.payload.status) {
            toast.success(response.payload.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            setToken("Dummy_Token")
            history.push('/Dashboard')
        }

        setLoader(false)
    }
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event)
        }
    }


    return ReactDom.createPortal(
        <div className="main d-flex justify-content-center w-100" style={{ backgroundImage: `url(${BackGroundPic})`, backgroundSize: "cover", backgroundPosition: "center" }} >
            <ToastContainer />
            <main className="content d-flex p-0">
                <div className="container d-flex flex-column">
                    <div className="row h-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
                                <div style={{ paddingBottom: "290px", marginLeft: "850px" }}>
                                    {/* <div className="text-center">
                                        <h1 className="h2">&nbsp;</h1>
                                        <p className="lead" style={{ color: "White" }}>
                                            <h1 style={{ color: "red" }}><b>Inventory System</b></h1>
                                        </p>
                                    </div> */}

                                    <div className="text-center">
                                        <Row>
                                            <h1 style={{
                                                fontSize: "50px",
                                                color: "white",
                                                fontWeight: "bold",
                                                marginBottom: "10%",
                                                display: "inline-block",
                                                fontFamily: "Agency FB",
                                            }}>
                                                Stock Keeper
                                            </h1>
                                        </Row>


                                    </div>
                                    <div className="mb-3" style={{ width: '4in', marginTop: "-2%" }}>
                                        {/* <label className="form-label">user name</label> */}
                                        <input
                                            // style={{ backgroundColor: "transparent", border: "none", boxShadow: "none", outline: "none", color: "#fff", borderBottom: "1px solid #ccc" }}
                                            className="form-control form-control-lg"
                                            type="text"
                                            name="email"
                                            value={formData.username}
                                            onChange={e => setFormData({ ...formData, username: e.target.value })}
                                            placeholder="Enter LoginID" />
                                    </div>
                                    <div className="mb-3" style={{ width: '4in' }}>
                                        {/* <label className="form-label">Password</label> */}
                                        <input
                                            className="form-control form-control-lg"
                                            type="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={e => setFormData({ ...formData, password: e.target.value })}
                                            onKeyPress={handleKeyPress}
                                            placeholder="Enter Password" />
                                        <small>
                                            {/* <a href="pages-reset-password.html">Forgot password?</a> */}
                                        </small>
                                    </div>
                                    <div className="text-center mt-3">

                                        <button
                                            style={{ width: '4in' }}
                                            onClick={handleSubmit}
                                            className="btn btn-lg btn-primary"
                                            disabled={loader == true ? true : (formData.username).trim() == "" ? true : (formData.password).trim() == "" ? true : false}
                                        >
                                            {
                                                loader ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <>Sign in</>
                                                )
                                            }

                                        </button>
                                        {/* <button type="submit" class="btn btn-lg btn-primary">Sign in</button> */}
                                    </div>

                                </div>

                                {/* <div style={{ textAlign: "right", paddingBottom: "-2%", paddingRight: "0.5%" }}>
                                    <button style={{ width: "2in" }} className="hover-button">Projected By</button>
                                </div> */}





                                {/* <div className="card">
                                    <div className="card-body">
                                        <div className="m-sm-4">
                                            <div className="text-center">
                                                <img src={img} alt="Chris Wood" className="img-fluid rounded-circle" width={132} height={132} />
                                            </div>
                                            <form>
                                                <div className="mb-3">
                                                    <label className="form-label">user name</label>
                                                    <input
                                                        style={{ backgroundColor: "transparent", border: "none", boxShadow: "none", outline: "none", color: "#fff", borderBottom: "1px solid #ccc" }}
                                                        className="form-control form-control-lg"
                                                        type="text"
                                                        name="email"
                                                        value={formData.username}
                                                        onChange={e => setFormData({ ...formData, username: e.target.value })}
                                                        placeholder="Enter your username" />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Password</label>
                                                    <input
                                                        className="form-control form-control-lg"
                                                        type="password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={e => setFormData({ ...formData, password: e.target.value })}
                                                        placeholder="Enter your password" />
                                                    <small>
                                                        <a href="pages-reset-password.html">Forgot password?</a>
                                                    </small>
                                                </div>
                                                <div>
                                                    <div className="form-check align-items-center">
                                                        <input id="customControlInline" type="checkbox" className="form-check-input" defaultValue="remember-me" name="remember-me" defaultChecked />
                                                        <label className="form-check-label text-small" htmlFor="customControlInline">Remember me next time</label>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-3">

                                                    <button
                                                        onClick={handleSubmit}
                                                        className="btn btn-lg btn-primary"
                                                        disabled={loader == true ? true : (formData.username).trim() == "" ? true : (formData.password).trim() == "" ? true : false}
                                                    >
                                                        {
                                                            loader ? (
                                                                <CircularProgress />
                                                            ) : (
                                                                <>Sign in</>
                                                            )
                                                        }

                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>, document.getElementById('body')

    )
}

export default Login