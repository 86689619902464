import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from "react-redux"
import { hide_divs, getCurrentDate } from "./../../Services/Common"
import ReactDom from "react-dom"
import { useSelector, useDispatch } from "react-redux"
import { BufferStock_Report } from "../../Services/ItemAPI"
import GlobalLoader from "../layout/GlobalLoader"

function setPageSize(cssPageSize) {

    const style = document.createElement('style');
    style.innerHTML = `@page {

    margin-top: 2%;
    margin-bottom: 2%;

};
    `;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function BufferStockReport() {
    const query = new URLSearchParams(window.location.search)
    const CatID = query.get('CatID')
    const CompName = useSelector(({ login }) => login.UserCompany[0]?.CompName)
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const UserName = useSelector(({ login }) => login?.user[0]?.UserName)
    const [ItemCategory, setItemCategory] = useState("")
    const [ListGridData, setListGridData] = useState([])
    const [loading, setLoading] = useState(false)
    document.title = "Minimum Stock Report"

    useEffect(() => {
          var _MinStkReportData = JSON.parse(localStorage.getItem("MinStkReportData"))
        setItemCategory(_MinStkReportData.SelectedCategory)
        setLoading(true)
        hide_divs()
        setPageSize('Portrait')
        fetchAPIData()
    }, [])

    async function fetchAPIData() {
        const response = await BufferStock_Report(CompID, CatID)
        setListGridData(response.data.Data)
        setLoading(false)
    }

    return ReactDom.createPortal(
        <Row className='report-font' style={{ fontSize: '15px' }}>
            <Col>
                <div  >
                    <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black", marginTop: '3%' }}>
                        <Col sm={12} md={12} xl={12}>
                            <h1 style={{ fontWeight: "bold" }}> {CompName} </h1>
                            Minimum Stock Report
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '2%', color: "black", marginLeft: '1.5%' }}  >
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            <h3><b>Item Category:</b></h3>
                        </Col>
                        <Col sm={4} md={4} xl={4} style={{ paddingLeft: "15px" }} >
                            {ItemCategory}
                        </Col>
                        {/* <Col sm={3} md={3} xl={3} style={{ textAlign: "right" }}>
                            <b>To Date:</b>
                        </Col>
                        <Col sm={3} md={3} xl={3}  >
                            {ToDate_}
                        </Col> */}

                    </Row>
                    <Row className='row-margin-left row-margin-right' style={{ marginTop: '2%' }}>
                        <Col>
                            <div id='table_div' className="row-margin-top" >
                                <table id='report_table' className='report_table' width="95%" style={{ marginLeft: "2%" }}>
                                    <thead id="thead" style={{ borderBottom: '2px solid black', borderTop: '2px solid black', color: "black" }}>
                                        <tr style={{ border: "none" }}>
                                            {/*  */}
                                            {/* id='vno_td' */}
                                            <th style={{ width: '8%', textAlign: 'left', fontWeight: "bold" }} >Sr#</th>
                                            {/* id='type_td' */}
                                            <th style={{ width: '23%', textAlign: 'left', fontWeight: "bold" }} >Item Name</th>
                                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >Cat Name</th>
                                            <th style={{ width: '24%', textAlign: 'left', fontWeight: "bold" }} >Dept Name</th>
                                            <th style={{ width: '13%', textAlign: 'center', fontWeight: "bold" }} >Stock Qty</th>
                                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold", color: "red" }} >Reorder Qty</th>
                                            {/* <th style={{ width: '17%', textAlign: 'center', fontWeight: "bold" }} >Buffer Quantity</th> */}
                                            {/* id='balance_td' */}
                                            {/* <th style={{ width: '17%', textAlign: 'center', fontWeight: "bold" }} >Stock In</th>
                                            <th style={{ width: '17%', textAlign: 'center', fontWeight: "bold" }} >Stock Out</th>
                                            <th style={{ width: '13%', textAlign: 'center', fontWeight: "bold" }} >Bal Qty</th> */}



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ListGridData.map((value, index) => {

                                                return (
                                                    <tr >
                                                        <td style={{ textAlign: 'left' }}>{index + 1}</td>
                                                        <td style={{ textAlign: 'left' }} >{value.ItemName}</td>
                                                        <td style={{ textAlign: 'left' }} >{value.CatName}</td>
                                                        <td style={{ textAlign: 'left' }} >{value.PartyName}</td>
                                                        <td style={{ textAlign: 'center' }} >{value.StockQty}</td>
                                                        <td style={{ textAlign: 'center', color: "red" }} >{value.BufferStock - value.StockQty}</td>
                                                        {/* <td style={{ textAlign: 'center' }} >{value.BufferStock}</td> */}
                                                        {/* <td style={{ textAlign: 'center' }} >{value.PurchaseQty}</td>
                                                        <td style={{ textAlign: 'center' }} >{value.SaleQty}</td>
                                                        <td style={{ textAlign: 'center' }} >{value.BalQty}</td> */}

                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                    <tr style={{ borderBottom: '2px solid black' }}>
                                    </tr>
                                </table>
                                <div >

                                    <div style={{ marginTop: '5%', marginLeft: "1.5%" }}>
                                        &nbsp;

                                        <b style={{ fontWeight: "bold" }}>Note:</b> This Report only shows items less than their Minimum Quantity defined by User.
                                    </div>

                                </div>
                                <div >

                                    <div style={{ marginTop: '5%', marginLeft: "1.5%" }}>
                                        &nbsp;

                                        Username: {UserName}   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        Date: {`${getCurrentDate()}`}
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <GlobalLoader
                    open={loading}
                />
            </Col>
        </Row>, document.getElementById('body')
    )
}

export default BufferStockReport