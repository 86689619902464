import React, { useEffect, useState } from 'react'
import ReactDom from "react-dom"
import { get_unshipped_orders, update_unshipped_order_notes } from '../../Services/Orders';
import EnhancedTable from './Table';
import { MutatingDots } from 'react-loader-spinner'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { TextField } from '@material-ui/core';
import { show_hidden_divs } from '../../Services/Common';
import GlobalFilter from '../layout/GlobalLoader';

function UnshippedOrderList() {

    const [data, setData] = React.useState([])
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [Report_Id, setReport_Id] = React.useState(null)
    const [loader, setLoader] = useState(true)
    const [open, setOpen] = React.useState(false);
    const [height, setHeight] = useState(0)
    
    const [notes_data, setNotes_data] = useState({
        OrderItemId: null,
        notes: ''
    })

    useEffect(() => {
        show_hidden_divs();
        getUnshippedOrders();
    }, [])

    const handleClickOpen = (OrderItemId, notes) => {

        setNotes_data({OrderItemId:OrderItemId, notes: notes})
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    const getUnshippedOrders = async () => {
        setLoader(true)
        const _data = await get_unshipped_orders();
        setData(_data)
        setLoader(false)
    }


    const columns = [
        {
            Header: 'ID',
            accessor: 'ID',
            width: 100,
            Cell: ({ row }) => {
                return <div style={{ width: '50px' }} >{row.original.ID}</div>
            }
        },
        {
            Header: 'OrderID',
            accessor: 'OrderId',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.OrderId}</div>
            }
        },
        {
            Header: 'OrderItemID',
            accessor: 'OrderItemId',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.OrderItemId}</div>
            }
        },
        {
            Header: 'PurchaseDate',
            accessor: 'PurchaseDate',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.PurchaseDate}</div>
            }
        },
        {
            Header: 'PaymentsDate',
            accessor: 'PaymentsDate',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.PaymentsDate}</div>
            }
        },
        {
            Header: 'ReportingDate',
            accessor: 'ReportingDate',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.ReportingDate}</div>
            }
        },
        {
            Header: 'PromiseDate',
            accessor: 'PromiseDate',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.PromiseDate}</div>
            }
        },
        {
            Header: 'DaysPastPromise',
            accessor: 'DaysPastPromise',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.DaysPastPromise}</div>
            }
        },
        {
            Header: 'BuyerEmail',
            accessor: 'BuyerEmail',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.BuyerEmail}</div>
            }
        },
        {
            Header: 'BuyerName',
            accessor: 'BuyerName',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.BuyerName}</div>
            }
        },
        {
            Header: 'BuyerPhoneNumber',
            accessor: 'BuyerPhoneNumber',
            Cell: ({ row }) => {
                return <div style={{ width: '190px' }} >{row.original.BuyerPhoneNumber}</div>
            }
        }, {
            Header: 'SKU',
            accessor: 'SKU',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.SKU}</div>
            }
        },
        {
            Header: 'ProductName',
            accessor: 'ProductName',
            Cell: ({ row }) => {
                return <div style={{ width: '600px' }} >{row.original.ProductName}</div>
            }
        },
        {
            Header: 'QuantityPurchased',
            accessor: 'QuantityPurchased',
            Cell: ({ row }) => {
                return <div style={{ width: '170px' }} >{row.original.QuantityPurchased}</div>
            }
        }, {
            Header: 'QuantityShipped',
            accessor: 'QuantityShipped',
            Cell: ({ row }) => {
                return <div style={{ width: '170px' }} >{row.original.QuantityShipped}</div>
            }
        },
        {
            Header: 'QuantityToShip',
            accessor: 'QuantityToShip',
            Cell: ({ row }) => {
                return <div style={{ width: '170px' }} >{row.original.QuantityToShip}</div>
            }
        },
        {
            Header: 'ShipServiceLevel',
            accessor: 'ShipServiceLevel',
            Cell: ({ row }) => {
                return <div style={{ width: '170px' }} >{row.original.ShipServiceLevel}</div>
            }
        },
        {
            Header: 'RecipientName',
            accessor: 'RecipientName',
            Cell: ({ row }) => {
                return <div style={{ width: '170px' }} >{row.original.RecipientName}</div>
            }
        },
        {
            Header: 'ShipAddress1',
            accessor: 'ShipAddress1',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.ShipAddress1}</div>
            }
        },
        {
            Header: 'ShipAddress2',
            accessor: 'ShipAddress2',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.ShipAddress2}</div>
            }
        },
        {
            Header: 'ShipAddress3',
            accessor: 'ShipAddress3',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.ShipAddress3}</div>
            }
        },
        {
            Header: 'ShipCity',
            accessor: 'ShipCity',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.ShipCity}</div>
            }
        },
        {
            Header: 'ShipState',
            accessor: 'ShipState',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.ShipState}</div>
            }
        },
        {
            Header: 'ShipPostalCode',
            accessor: 'ShipPostalCode',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.ShipPostalCode}</div>
            }
        },
        {
            Header: 'ShipCountry',
            accessor: 'ShipCountry',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.ShipCountry}</div>
            }
        },
        {
            Header: 'IsBusinessOrder',
            accessor: 'IsBusinessOrder',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.IsBusinessOrder}</div>
            }
        },
        {
            Header: 'PurchaseOrderNumber',
            accessor: 'PurchaseOrderNumber',
            Cell: ({ row }) => {
                return <div style={{ width: '180px' }} >{row.original.PurchaseOrderNumber}</div>
            }
        },
        {
            Header: 'PriceDesignation',
            accessor: 'PriceDesignation',
            Cell: ({ row }) => {
                return <div style={{ width: '150px' }} >{row.original.PriceDesignation}</div>
            }
        },
        {
            Header: 'IsPrime',
            accessor: 'IsPrime',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.IsPrime}</div>
            }
        },
        {
            Header: 'ShipmentStatus',
            accessor: 'ShipmentStatus',
            Cell: ({ row }) => {
                return <div style={{ width: '130px' }} >{row.original.ShipmentStatus}</div>
            }
        },
        {
            Header: 'CreatedDateTime',
            accessor: 'CreatedDateTime',
            Cell: ({ row }) => {
                return <div style={{ width: '200px' }} >{row.original.CreatedDateTime}</div>
            }
        },
        {
            Header: 'OENotes',
            accessor: 'OENotes',
            Cell: ({ row }) => {
                
                return (
                    Object.keys(row.original.OENotes).length === 0 ?(
                        <div onClick={e => handleClickOpen(row.original.OrderItemId, "")} style={{ width: '200px' }} >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                    ) :(
                        <div onClick={e => handleClickOpen(row.original.OrderItemId, row.original.OENotes)} style={{ width: '200px' }} >{row.original.OENotes}</div>
                    )
                )
            }
        }
    ]



    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }

    useEffect(() => {
      
        const  _content = document.getElementById('content');

        if(_content){
            setHeight(_content.clientHeight);
            
        }



    }, [])
    


    return ReactDom.createPortal(
        <div className="col-md-12">

            <div class="card" style={{height: height+'px'}} >
                <div class="card-body">

                    <EnhancedTable
                        columns={columns}
                        data={data}
                        setData={setData}
                        updateMyData={updateMyData}
                        skipPageReset={skipPageReset}
                        Report_Id={Report_Id}
                        setReport_Id={setReport_Id}
                        height={height}
                        
                    />

                </div>
            </div>

            <GlobalFilter 
                open={loader}
            />

            <UnshippedOrderModal 
                open={open}
                handleClose={handleClose}
                notes_data={notes_data}
                setNotes_data={setNotes_data}
                getUnshippedOrders={getUnshippedOrders}
            />

        </div>
        ,
        document.getElementById('content')
    )
}


function UnshippedOrderModal({open, handleClose, notes_data, setNotes_data, getUnshippedOrders}) {

    const updateUnshippedOrderNotes = async() =>{
        const code = await update_unshipped_order_notes(notes_data);

        if(parseInt(code) === 200){
            
            setTimeout(() => {
                handleClose()
                getUnshippedOrders();
            }, 500);
        }
    }



    return(
        <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Order Item ID : {notes_data.OrderItemId}</DialogTitle>
        <DialogContent>
            <TextField
            id="outlined-multiline-static"
            label="OENotes"
            multiline
            value={notes_data.notes}
            onChange={e => setNotes_data({...notes_data, notes: e.target.value})}
            rows={4}
            fullWidth
            />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={updateUnshippedOrderNotes} >Save</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
   
    )
}

export default UnshippedOrderList