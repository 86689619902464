import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Checkbox, Card } from '@material-ui/core'
import { Save, Close, Print } from "@material-ui/icons";
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { getMuiTheme, getCurrentDate } from '../../Services/Common'
import { StockMovementReport } from "../../Services/TransactionAPI"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CSVLink, CSVDownload } from "react-csv"

const StockMovModal = (props) => {
    const { open, handleClose } = props;
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const [FromDate, setFromDate] = useState(getFirstDayOfCurrentMonth())
    const [ToDate, setToDate] = useState(getCurrentDate)
    const [StkMovData, setStkMovData] = useState([])

    useEffect(() => {
        fetchAPIData(FromDate, ToDate)
    }, [open])

    const handleSubmit = async () => {

        handleClose()

    }
    function getFirstDayOfCurrentMonth() {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const year = firstDayOfMonth.getFullYear();
        const month = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayOfMonth.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    async function fetchAPIData(FromDate, ToDate) {
        setStkMovData([])
        const response = await StockMovementReport(CompID, FromDate, ToDate)
        setStkMovData(response.data.Data)
        // setLoading(false)
    }
    const headers = [
        { label: 'ItemName', key: 'ItemName' },
        { label: 'StockQTy', key: 'StockQTy' },
        { label: 'StockOut', key: 'PurchaseQty' },
        { label: 'StockIn', key: 'SaleQty' },
        { label: 'BalQty', key: 'BalQty' },
    ];

    const renderTooltip_ItemList_CSV = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Download CSV
        </Tooltip>
    );
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="ThemeHeader">
                <h style={{ textAlign: "left" }}>Stock Movement Report</h>
            </DialogTitle>
            <DialogContent>
                <Row style={{ marginTop: "15px", fontWeight: "bold" }}>
                    <Col md={6} sm={6} xl={6} lg={6} style={{ fontSize: "16px" }}>
                        <TextField
                            name="FromDate"
                            id="FromDate"
                            label="From Date"
                            type="date"
                            value={FromDate}
                            autoFocus
                            onChange={e => {
                                setFromDate(e.target.value)
                            }}
                            fullWidth

                        />
                    </Col>
                    <Col md={6} sm={6} xl={6} lg={6}>
                        <TextField
                            name="ToDate"
                            id="ToDate"
                            label="To Date"
                            type="date"
                            value={ToDate}
                            autoFocus
                            onChange={e => {
                                setToDate(e.target.value)
                            }}
                            fullWidth

                        />
                    </Col>
                </Row>
            </DialogContent>
            <DialogActions>
                <CSVLink
                    filename={`StockMovReport(${getCurrentDate()}).csv`}
                    data={StkMovData}
                    headers={headers}
                    className="popup-button"
                    onClick={() =>
                        fetchAPIData(FromDate, ToDate)
                    }
                >
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip_ItemList_CSV}
                    >
                        <FileDownloadIcon style={{ marginBottom: "0.5%", fontSize: "35px" }} />
                    </OverlayTrigger>
                </CSVLink>

                &nbsp;&nbsp;&nbsp;
                <button className="ThemeHeader popup-button" autoFocus
                    onClick={e => {
                        var mtoken = sessionStorage.getItem('token')
                        localStorage.setItem('token', sessionStorage.getItem('token'))
                        var obj = {
                            From_Date: FromDate,
                            To_Date: ToDate,
                        }
                        localStorage.setItem("StockMov_ReportData", JSON.stringify(obj))
                        window.open("/StockMov_Report", "_blank")
                    }}
                >
                    <Print />
                </button>
                <button className="ThemeHeader popup-button" onClick={handleClose}><Close /></button>
            </DialogActions>
        </Dialog >
    )
}

export default StockMovModal