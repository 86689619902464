import { REACT_BASE_URL } from "./Common"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const GenerateMaxTrNo = async (CompID, TrType) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/GenerateMaxTrNo/${CompID}/${TrType}`)
    return response;
}

export const SelectTrList = async (CompID, TrType, Month, DateWise, FromDate, ToDate, Post) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/SelectTrList/${CompID}/${TrType}/${Month}/${DateWise}/${FromDate}/${ToDate}/${Post}`)
    return response;
}

export const StockMovementReport = async (CompID, FromDate, ToDate) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/StockMovementReport/${CompID}/${FromDate}/${ToDate}`)
    return response;
}

export const PurchaseSaleReport = async (CompID, TrType, FromDate, ToDate) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/PurchaseSaleReport/${CompID}/${TrType}/${FromDate}/${ToDate}`)
    return response;
}

export const DashboardPurchaseTotal = async (CompID, TrType, FromDate, ToDate) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/DashboardPurchaseTotal/${CompID}/${TrType}/${FromDate}/${ToDate}`)
    return response;
}

export const PartyWisePurchaseSaleReport = async (CompID, PartyID, TrType, FromDate, ToDate) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/PartyWisePurchaseSaleReport/${CompID}/${PartyID}/${TrType}/${FromDate}/${ToDate}`)
    return response;
}

export const ItemWisePurchaseSaleReport = async (CompID, ItemID, TrType, FromDate, ToDate) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/ItemWisePurchaseSaleReport/${CompID}/${ItemID}/${TrType}/${FromDate}/${ToDate}`)
    return response;
}

export const TopPurSaleItem = async (CompID, TrType) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/TopPurSaleItem/${CompID}/${TrType}`)
    return response;
}

export const LastPurchaseRate = async (CompID, TrType, ItemID) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/LastPurchaseRate/${CompID}/${TrType}/${ItemID}`)
    return response;
}

export const LastAveragePurRate = async (CompID, ItemID) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/LastAveragePurRate/${CompID}/${ItemID}`)
    return response;
}

export const SelectTrMaster = async (TrID, CompID) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/SelectTrMaster/${TrID}/${CompID}`)
    return response;
}

export const SelectTrDetail = async (TrID) => {
    const response = await axios.get(`${REACT_BASE_URL}/TransactionMaster/SelectTrDetail/${TrID}`)
    return response;
}

export const DeleteTrDetail = async (TrID) => {
    const response = await axios.delete(`${REACT_BASE_URL}/TransactionMaster/DeleteTrDetail/${TrID}`)
    return response;
}

export const SaveTransaction = async (TransactionMaster, TransactionDetail, DeletedTrDetailData, IsAddNew) => {
    const response = await axios.post(`${REACT_BASE_URL}/TransactionMaster/SaveTransaction`, { TransactionMaster, TransactionDetail, DeletedTrDetailData, IsAddNew })
    return response;
}

export const InvoicePost = async (TrID, CompID) => {
    const response = await axios.post(`${REACT_BASE_URL}/TransactionMaster/InvoicePost`, { TrID, CompID })
    return response;
}