import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Checkbox, Card } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import { Save, Close } from "@material-ui/icons";
import { SaveItem } from '../../Services/ItemAPI'
import { Row, Col } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import LoadingButton from '@mui/lab/LoadingButton'
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { SelectGroupParty } from '../../Services/PartiesAPI'
import { SelectItemCat } from "../../Services/ItemCatAPI"

const ItemsModal = (props) => {
    const { open, handleClose, ItemID, fetchData, SelectedGroup, reload, setReload, itemname,itemcode, stockqty, buffstk, rate, isactive, itemdept, itemcat } = props;
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const [Rate, setRate] = useState(0)
    const [StockQty, setStockQty] = useState('')
    const [BufferStock, setBufferStock] = useState(0)
    const [Email, setEmail] = useState('')
    const [ItemName, setItemName] = useState('')
    const [ItemCode, setItemCode] = useState('')
    const [IsActive, setIsActive] = useState(true)
    const [ErrorMsg, setErrorMsg] = useState({ NameError: '', StockQtyError: '', RateError: '', DeptError: '', CatError:'' })
    const [Loading, setLoading] = useState(false)
    const [PartiesList, setPartiesList] = useState([])
    const [SelectedParty, setSelectedParty] = useState(null)
    const [CatList, setCatList] = useState([])
    const [SelectedCat, setSelectedCat] = useState(null)

    useEffect(async () => {
        const CatResponse = await SelectItemCat(CompID)
        const _CatList = CatResponse?.data?.Data
        setCatList(_CatList)
        const PartyResponse = await SelectGroupParty(CompID, "Stock Out", 1)
        const _PartiesList = PartyResponse?.data?.Data
        setPartiesList(_PartiesList)
        if (open) {
            setItemName('')
            setItemCode('')
            setStockQty('')
            setBufferStock(0)
            setRate(0)
            setSelectedParty(null)
            setSelectedCat(null)
            if (ItemID > 0) {
                setItemName(itemname)
                setItemCode(itemcode)
                setStockQty(stockqty)
                setBufferStock(buffstk)
                setRate(rate)
                setIsActive(isactive)
                // setSelectedParty(itemdept)
                const initialSelectedParty = _PartiesList.find(party => party.PartyID === itemdept);
                setSelectedParty(initialSelectedParty);

                const initialSelectedCat = _CatList.find(cat => cat.CatID === itemcat);
                setSelectedCat(initialSelectedCat);
            }
        }
    }, [open])

    async function fetchCat() {
        const response = await SelectItemCat(CompID)
        const obj = {
            CatName: "Select All",
            CatID: 0
        }
        response.data.Data.splice(0, 0, obj)
        if (response.data.Data.length > 0) {
            setSelectedCat(response.data.Data[0])
            fetchData(response.data.Data[0].CatID)
        }
        setCatList(response.data.Data)
        setLoading(false)
    }
    const validate = () => {
        if (!ItemName.trim()) {
            setErrorMsg({ ...ErrorMsg, NameError: 'Name Required' })
            document.getElementById('ItemName').focus()
            return false;
        }
        if (!SelectedParty) {
            setErrorMsg({ ...ErrorMsg, NameError: '', DeptError: 'Select Dept' })
            document.getElementById('ItemName').focus()
            return false;
        }
        if (!SelectedCat) {
            setErrorMsg({ ...ErrorMsg, NameError: '', DeptError: '',CatError:'Select Category' })
            document.getElementById('ItemName').focus()
            return false;
        }
        // if (!StockQty) {
        //     setErrorMsg({ NameError: '', StockQtyError: 'Stock Qty Required' })
        //     document.getElementById('disable-clearable').focus()
        //     return false;
        // }
        // if (!Rate) {
        //     setErrorMsg({ NameError: '', StockQtyError: '', RateError: 'Rate Required' })
        //     document.getElementById('disable-clearable').focus()
        //     return false;
        // }
        setErrorMsg({ NameError: '', DeptError: '', CatError:'' })
        return true;
    }

    const handleSubmit = async () => {
        if (validate()) {
            const ItemData = {
                ItemID: ItemID,
                CompID: CompID,
                PartyID: SelectedParty?.PartyID,
                CatID: SelectedCat?.CatID,
                ItemName: ItemName,
                // StockQty: StockQty,
                BufferStock: BufferStock,
                Rate: Rate,
                IsActive: IsActive ? 1 : 0
            }
            const isAddNew = ItemID > 0 ? false : true
            setLoading(true)
            const response = await SaveItem(ItemData, isAddNew)
            console.log(response)
            if (response.status === 200) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
                handleClose()
                fetchData(SelectedCat?.CatID)
            }
        }

    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="ThemeHeader">
                {
                    ItemID == 0 ? (
                        `Add New Item`
                    ) : (
                        `Edit Item ( ${itemcode} )`
                        //  `Edit ${itemname}`
                    )
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    name="ItemName"
                    id="ItemName"
                    label="Item Name"
                    value={ItemName}
                    onChange={e => {
                        setItemName(e.target.value)
                        setErrorMsg({ ...ErrorMsg, NameError: '' })
                    }}
                    fullWidth

                />
                <span style={{ color: "red" }}>{ErrorMsg.NameError}</span>
                <Row>
                    {/* <Col sm={6} md={6} xl={6} lg={6}>
                        <TextField
                            name="StockQty"
                            id="StockQty"
                            label="Stock Qty"
                            value={StockQty}
                            onChange={e => {
                                setStockQty(e.target.value)
                                setErrorMsg({ ...ErrorMsg, StockQtyError: '' })
                            }}
                            InputProps={{
                                readOnly: true
                            }}
                            fullWidth
                        />
                        <span style={{ color: "red" }}>{ErrorMsg.StockQtyError}</span>
                    </Col> */}
                    <Col sm={6} md={6} xl={6} lg={6}>
                        <TextField
                            name="BufferStock"
                            id="BufferStock"
                            label="Minimum Stock"
                            value={BufferStock}
                            onChange={e => {
                                setBufferStock(e.target.value)
                                // setErrorMsg({ ...ErrorMsg, RateError: '' })
                            }}
                            fullWidth

                        />
                        {/* <span style={{ color: "red" }}>{ErrorMsg.RateError}</span> */}
                    </Col>
                    <Col sm={6} md={6} xl={6} lg={6}>
                        <TextField
                            name="Rate"
                            id="Rate"
                            label="Rate"
                            value={Rate}
                            onChange={e => {
                                setRate(e.target.value)
                                // setErrorMsg({ ...ErrorMsg, RateError: '' })
                            }}
                            fullWidth

                        />
                        {/* <span style={{ color: "red" }}>{ErrorMsg.RateError}</span> */}
                    </Col>
                    {/* <div className="col-md-3 col-lg-3 col-sm-3" style={{ marginTop: "4px" }}> */}
                    <Col sm={6} md={6} xl={6} lg={6}>
                        <Autocomplete
                            id="disable-clearable"
                            options={PartiesList}
                            getOptionLabel={(option) => option.PartyName}
                            value={SelectedParty}
                            onChange={(e, value) => {
                                setSelectedParty(value)
                                setErrorMsg({ ...ErrorMsg, DeptError: '' })
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Department" variant="standard" />
                            )}
                            fullWidth
                        />
                        <span style={{ color: "red" }}>{ErrorMsg.DeptError}</span>
                        {/* </div> */}
                    </Col>
                    <Col sm={6} md={6} xl={6} lg={6}>
                        <Autocomplete
                            id="disable-clearable"
                            disableClearable
                            options={CatList}
                            getOptionLabel={(option) => option.CatName}
                            value={SelectedCat}
                            onChange={(e, value) => {
                                setSelectedCat(value)
                                // setLoading(true)
                                // fetchData(value.CatID)
                                setErrorMsg({ ...ErrorMsg, CatError: '' })
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Item Category" variant="standard" />
                            )}
                            fullWidth
                        />
                        <span style={{ color: "red" }}>{ErrorMsg.CatError}</span>
                    </Col>
                </Row>
            </DialogContent>
            <FormGroup style={{ marginLeft: "22px" }}>
                <FormControlLabel control={<Checkbox checked={IsActive}
                    onChange={e => {
                        setIsActive(e.target.checked)
                    }}
                    style={{ color: "#293042" }}
                />} label="Active" />
            </FormGroup>
            <DialogActions>
                <LoadingButton
                    style={{ color: "white", backgroundColor: "#293042", height: "30px" }}
                    className="ThemeHeader popup-button"
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    // loading={Loading}
                    // loadingPosition="start"
                    startIcon={<Close style={{ fontSize: '22px', marginLeft: "10px" }} />}
                    variant="contained"
                />
                <LoadingButton
                    style={{ color: "white", backgroundColor: "#293042", height: "30px" }}
                    className="ThemeHeader popup-button"
                    size="small"
                    color="secondary"
                    onClick={handleSubmit}
                    loading={Loading}
                    loadingPosition="start"
                    startIcon={<SaveAltIcon style={{ fontSize: '22px', marginLeft: "10px" }} />}
                    variant="contained"
                />
                {/* <button className="ThemeHeader popup-button" onClick={handleClose}><Close /></button>
                <button className="ThemeHeader popup-button" onClick={handleSubmit} >
                    <Save />
                </button> */}
            </DialogActions>
        </Dialog>
    )
}

export default ItemsModal