import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core'
import { Save, Cancel } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Edit, AccessibilityNew, Add } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux"
import { get_Role_List, RoleName, save_role } from './UsersSlice'
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme } from '../../Services/Common'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserAccess() {
    const dispatch = useDispatch();
    document.title = "OE Squills / Users Access List"
    const userRolesList = useSelector(({ UsersSlice }) => UsersSlice.userRolesList)
    const loading = useSelector(({ UsersSlice }) => UsersSlice.loading)
    const [open, setOpen] = useState(false);
    const [roleData, setRoleData] = useState({ roleId:0, roleName:''})
    const [reload, setReload] = useState(false)

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    useEffect(() => {
        onLoad();
    }, [reload])

    const onLoad = async () => {
        await dispatch(get_Role_List())
    }
    const columns = [
        {
            name: "rno",
            label: "#",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "RoleName",
            label: "Role Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (userRolesList[dataIndex] != null)
                        return (
                            <>
                            <Link
                                to={"#"}
                                onClick={e => {
                                    setRoleData({
                                        roleId: userRolesList[dataIndex].RoleId,
                                        roleName: userRolesList[dataIndex].RoleName
                                    })
                                    handleClickOpen()
                                }} >
                                <Edit />
                            </Link>
                            &ensp;
                            <Link
                                to={"/UserAccess"}
                                onClick={e => {
                                    dispatch(
                                        RoleName(
                                            {RoleName: userRolesList[dataIndex].RoleName, 
                                                RoleId: userRolesList[dataIndex].RoleId }
                                        ))
                                }} >
                                 <AccessibilityNew />
                            </Link>
                            </>
                        );
                }
            }
        },
    ];

    return ReactDom.createPortal(

        <div className="col-md-12">
             {/* <ToastContainer /> */}
            <div class="card" >
                <div class="card-body">
                <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            &nbsp;
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }} >
                            <button
                                type="button"
                                onClick={e =>{
                                    setRoleData({
                                        roleId: 0,
                                        roleName: ''
                                    })
                                    handleClickOpen()
                                }}
                                className="btn btn-primary waves-effect height-of-button"
                            > <Add /> Add New </button>
                        </div>
                    </div>
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            style={{ size: "200px" }}
                            data={userRolesList}
                            columns={columns}
                            options={{
                                selectableRows: false,
                                search: false,
                                download: false,
                                print: false,
                                viewColumns: false,
                                filter: false,
                                selectableRows: false,
                                selection: true,
                                toolbar: false,
                                paging: false,
                                headerStyle: {
                                    fontSize: 12
                                },
                            }
                            }
                        />
                    </MuiThemeProvider>

                    <GlobalLoader
                        open={loading}
                    />

                    <UserDialog 
                        handleClose={handleClose}
                        open={open}
                        dispatch={dispatch}
                        roleId={roleData.roleId}
                        Role_Name={roleData.roleName}
                        reload={reload}
                        setReload={setReload}
                    />
                </div>
            </div>
        </div>

        , document.getElementById('content')
    )
}

const UserDialog = (props) => {
    const {open , handleClose, roleId, Role_Name, dispatch, reload, setReload} = props;
    const [RoleName, setRoleName] = useState('')

    useEffect(() => {
        if(open){
            setRoleName(Role_Name)
        }
    }, [open])

    const handleSubmit = async () => {
        let data = JSON.stringify({
            RoleId: roleId,
            RoleName: RoleName
        })
        const response = await dispatch(save_role(data))

        toast.success(response.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

            handleClose()
            setReload(!reload)
        
    }
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {
                roleId == 0 ? (
                    "Add Role"
                ) : (
                    "Edit Role"
                )
            }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <TextField 
                        name="description"
                        id="description"
                        label="Role Name"
                        value={RoleName}
                        autoFocus
                        onChange={e=> setRoleName(e.target.value)}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleSubmit} autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserAccess