import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from "react-redux"
import { hide_divs, getCurrentDate } from "./../../Services/Common"
import ReactDom from "react-dom"
import { useSelector, useDispatch } from "react-redux"
import { PurchaseSaleReport } from "../../Services/TransactionAPI"

function setPageSize(cssPageSize) {

    const style = document.createElement('style');
    style.innerHTML = `@page {

    margin-top: 2%;
    margin-bottom: 2%;

};
    `;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function PurchaseSale_Report() {
    const CompName = useSelector(({ login }) => login.UserCompany[0]?.CompName)
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const UserName = useSelector(({ login }) => login?.user[0]?.UserName)
    const [FromDate_, setFromDate_] = useState("")
    const [ToDate_, setToDate_] = useState("")
    const [PurSaleReport_Data, setPurSaleReport_Data] = useState([])
    const [TrType, setTrType] = useState("")
    const [TotalAmount, setTotalAmount] = useState(0)
    document.title = "Stock Keeper / Purchase"

    useEffect(() => {
        hide_divs()
        var _PurSale_ReportData = JSON.parse(localStorage.getItem("PurSale_ReportData"))
        let _TrType
        if (_PurSale_ReportData.Modal_Title === "Purchase Report") {
            setTrType("Stock In")
            _TrType = "Stock In"
        }
        else {
            setTrType("Stock Out")
            _TrType = "Stock Out"

        }
        setFromDate_(_PurSale_ReportData.From_Date)
        setToDate_(_PurSale_ReportData.To_Date)
        fetchAPIData(_PurSale_ReportData.From_Date, _PurSale_ReportData.To_Date, _TrType)
        localStorage.removeItem("PurSale_ReportData")
    }, [])

    async function fetchAPIData(FromDate, ToDate, TrType) {
        const response = await PurchaseSaleReport(CompID, TrType, FromDate, ToDate)
        setPurSaleReport_Data(response.data.Data)
        Total_Amount(response.data.Data)
        // setLoading(false)
    }
    const Total_Amount = (array) => {
        let Amt = 0;

        array.map(value => {
            Amt += parseFloat(value.Amount);
        })
        setTotalAmount(Amt)
    }
    return ReactDom.createPortal(
        <Row className='report-font' style={{ fontSize: '18px' }}>
            <Col>
                <div  >
                    <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black", marginTop: '3%' }}>
                        <Col sm={12} md={12} xl={12}>
                            <h1 style={{ fontWeight: "bold" }}> {CompName} </h1>
                            {TrType == "Stock In" ? "Purchase Register" : "Consumption Register"}
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '2%', color: "black", marginLeft: '1.5%' }}  >
                        <Col sm={2} md={2} xl={2} style={{ fontWeight: "bold" }}>
                            <h3><b>From Date:</b></h3>
                        </Col>
                        <Col sm={4} md={4} xl={4} style={{ paddingLeft: "15px" }} >
                            {FromDate_}
                        </Col>
                        <Col sm={3} md={3} xl={3} style={{ textAlign: "right" }}>
                            <b>To Date:</b>
                        </Col>
                        <Col sm={3} md={3} xl={3}  >
                            {ToDate_}
                        </Col>

                    </Row>
                    <Row className='row-margin-left row-margin-right' style={{ marginTop: '2%' }}>
                        <Col>
                            <div id='table_div' className="row-margin-top" >
                                <table id='report_table' className='report_table' width="95%" style={{ marginLeft: "2%" }}>
                                    <thead id="thead" style={{ borderBottom: '2px solid black', borderTop: '2px solid black', color: "black" }}>
                                        <tr style={{ border: "none" }}>
                                            {/*  */}
                                            {/* id='vno_td' */}
                                            <th style={{ width: '8%', textAlign: 'left', fontWeight: "bold" }} >Sr#</th>
                                            {/* id='type_td' */}
                                            <th style={{ width: '17%', textAlign: 'center', fontWeight: "bold" }} >{TrType == "Stock In" ? "Purchase Date" : "Installation Date"}</th>

                                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Transaction No.</th>
                                            {/* id='balance_td' */}
                                            <th style={{ width: '28%', textAlign: 'left', fontWeight: "bold" }} > {TrType == "Stock In" ? "Party Name" : "Department"} </th>
                                            <th style={{ width: '17%', textAlign: 'center', fontWeight: "bold" }} >Amount</th>
                                            {/* <th style={{ width: '13%', textAlign: 'center', fontWeight: "bold" }} >Bal Qty</th> */}



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            PurSaleReport_Data.map((value, index) => {

                                                return (
                                                    <tr >
                                                        <td style={{ textAlign: 'left' }}>{index + 1}</td>
                                                        <td style={{ textAlign: 'center' }} >{value.TrDate}</td>
                                                        <td style={{ textAlign: 'center' }} >{value.TrNo}</td>
                                                        <td style={{ textAlign: 'left' }} >{value.PartyName}</td>
                                                        <td style={{ textAlign: 'center' }} >{value.Amount}</td>
                                                        {/* <td style={{ textAlign: 'center' }} >{value.BalQty}</td> */}

                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr style={{ fontWeight: 'bold', borderTop: '2px solid black', borderBottom: '2px solid black' }}>
                                            <td colSpan={3} style={{ textAlign: 'right' }}> Total :</td>

                                            {/* <td></td> */}
                                            <td></td>
                                            <td style={{ textAlign: 'center' }} >{TotalAmount.toFixed(2)}</td>


                                        </tr>
                                    </tbody>
                                    <tr style={{ borderBottom: '2px solid black' }}>
                                    </tr>
                                </table>
                                <div >

                                    {/* <div style={{ marginTop: '5%', marginLeft: "1.5%" }}>
                                        &nbsp;

                                        Username: {UserName}   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        Date: {`${getCurrentDate()}`}
                                    </div> */}

                                    <div style={{ marginTop: '5%' }}>
                                        &nbsp;
                                        <Row>
                                            <Col style={{ textAlign: "left", marginLeft: "2.2%" }} >Username: {UserName}</Col>
                                            <Col style={{ textAlign: "right", marginRight: "3%" }} >Date: {`${getCurrentDate()}`}</Col>
                                        </Row>
                                    </div>

                                </div>


                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>

        </Row>, document.getElementById('body')
    )
}

export default PurchaseSale_Report