import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Edit, AccessibilityNew, Add, Delete } from "@material-ui/icons";
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme, getCurrentDate } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import { setFormData } from './TransactionSlice';
import { SelectTrList } from '../../Services/TransactionAPI'
import { separateNumber } from './../ThousandSeparator'

function PurchaseSale() {
    const dispatch = useDispatch()
    const FormName = useSelector(({ TransactionSlice }) => TransactionSlice.FormName)
    const StockType = useSelector(({ TransactionSlice }) => TransactionSlice.StockType)
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const _Month = useSelector(({ TransactionSlice }) => TransactionSlice.TrData)
    const FilterData = useSelector(({ TransactionSlice }) => TransactionSlice.FilterData)
    const [loading, setLoading] = useState(false)
    const [InvoicesList, setInvoicesList] = useState([])
    const [SelectedMonth, setSelectedMonth] = useState(null)
    const [IsDateWise, setIsDateWise] = useState(false)
    const [All_Invoices, setAll_Invoices] = useState(false)
    const [FromDate, setFromDate] = useState(getCurrentDate)
    const [ToDate, setToDate] = useState(getCurrentDate)
    const [TotalInvoices, setTotalInvoices] = useState(0)
    const [TotalInvoicesAmt, setTotalInvoicesAmt] = useState(0)
    const [UnpostedCount, setUnpostedCount] = useState(0)
    const [TrType, setTrType] = useState("")
    const [ErrorMsg, setErrorMsg] = useState({ MonthError: '' })
    document.title = "Stock Keeper / Purchase"
    useEffect(() => {

        if (FilterData) {
            setSelectedMonth(_Month)
            setIsDateWise(FilterData.IsDateWise)
            setFromDate(FilterData.FromDate)
            setToDate(FilterData.ToDate)
            setAll_Invoices(FilterData.All_Invoices)
            const TrType = FormName === "Purchase" ? "Stock In" : "Stock Out"
            if (FormName) {
                setTrType(TrType)
                fetchData(TrType, _Month.value, FilterData.IsDateWise ? 1 : 0, FilterData.FromDate, FilterData.ToDate, FilterData.All_Invoices ? 1 : 0)
            }
            let obj = {
                AddNew: false,
                TrID: 0,
                TrData: null,
                FilterData: null,
            }
            dispatch(setFormData(obj))

        }
        else {
            const Month = new Date().getMonth();
            const currentMonth = MonthsList[Month];
            setSelectedMonth(currentMonth)
            const TrType = FormName === "Purchase" ? "Stock In" : "Stock Out"
            if (FormName) {
                setTrType(TrType)
                fetchData(TrType, currentMonth.value, IsDateWise ? 1 : 0, FromDate, ToDate, All_Invoices ? 1 : 0)
            }
            setLoading(true)
        }

    }, [FormName, StockType])

    async function fetchData(TrType, Month, DateWise, FromDate, ToDate, Post) {
        const response = await SelectTrList(CompID, TrType, Month, DateWise, FromDate, ToDate, Post)
        if (response.data.Data.length > 0) {
            setInvoicesList(response.data.Data)
            CalculateTotalInvAmt(response.data.Data)
        }
        else {
            setInvoicesList([])
            CalculateTotalInvAmt([])
        }
        setTotalInvoices(response.data.Data.length)
        setLoading(false)
    }

    const AddInvValidate = () => {
        if (!SelectedMonth) {
            setErrorMsg({ MonthError: 'Select Any Month' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        setErrorMsg({ MonthError: '' })
        return true;
    }
    const CalculateTotalInvAmt = (array) => {
        let totalAmount = 0;
        let count = 0;
        array.forEach((value) => {
            const amount = parseFloat(value.Amount);
            if (!value.Post) {
                count++
            }
            totalAmount += amount;
        });

        setTotalInvoicesAmt(separateNumber(totalAmount.toFixed(2)));
        setUnpostedCount(count);
        setLoading(false)
    };


    const MonthsList = [
        { title: 'January', value: 1 },
        { title: 'February', value: 2 },
        { title: 'March', value: 3 },
        { title: 'April', value: 4 },
        { title: 'May', value: 5 },
        { title: 'June', value: 6 },
        { title: 'July', value: 7 },
        { title: 'August', value: 8 },
        { title: 'September', value: 9 },
        { title: 'October', value: 10 },
        { title: 'November', value: 11 },
        { title: 'December', value: 12 },
    ]
    const columns = [
        {
            name: "TrNo",
            label: "Invoice#",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ fontSize: "11px" }}>{value}</div> // Adjust the font size as needed
                    );
                },
            }
        },
        {
            name: "TrDate",
            label: "Date",
            options: {
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "100px" }}),
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ fontSize: "11px" }}>{value}</div> // Adjust the font size as needed
                    );
                },
            }
        },
        {
            name: "PartyName",
            label: FormName === "Purchase" ? "Party Name" : "Department Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }}>{columnMeta.label}</div>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ fontSize: "11px" }}>{value}</div> // Adjust the font size as needed
                    );
                },
            }
        },
        {
            name: "ItemNames",
            label: "ItemNames",
            options: {
                filter: true,
                sort: true,
                width: "80px",
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }}>{columnMeta.label}</div>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ fontSize: "11px" }}>{value}</div> // Adjust the font size as needed
                    );
                },
            }
        },
        {
            name: "Amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const amount = InvoicesList[dataIndex]?.Amount;
                    const formattedAmount = amount ? separateNumber(parseFloat(amount).toFixed(2)) : "";
                    return <div style={{ fontSize: "11px" }}>{formattedAmount}</div>;
                },
            }
        },
        // {
        //     name: "PhoneNo",
        //     label: "Phone#",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customHeadLabelRender: (columnMeta) => {
        //             return (
        //                 <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
        //             )
        //         },
        //     }
        // },
        {
            name: "Post",
            label: "Posted",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Checkbox
                            style={{ color: '#293042' }}
                            checked={value}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    )
                },
            }
        },
        {
            name: "IsCancel",
            label: "Cancelled",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Checkbox
                            style={{ color: '#293042' }}
                            checked={value}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    )
                },
            }
        },
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (InvoicesList[dataIndex] != null)
                        return (
                            <>
                                <Link
                                    to={"/PurchaseInvoice"}
                                    onClick={e => {
                                        const FilterData = {
                                            All_Invoices,
                                            IsDateWise,
                                            FromDate,
                                            ToDate,
                                        }
                                        let obj = {
                                            AddNew: false,
                                            TrID: InvoicesList[dataIndex].TrID,
                                            TrData: SelectedMonth,
                                            FilterData,
                                        }
                                        dispatch(setFormData(obj))
                                    }}
                                >
                                    <Edit className="IconsColor popup-button" />
                                </Link>
                            </>
                        );
                }
            }
        },
    ];

    return ReactDom.createPortal(
        <div className="col-md-12">
            <ToastContainer />
            <div class="card" >
                <div class="card-body">
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <h1>{FormName}</h1>
                        </div>

                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }} >
                            <button
                                // style={{ backgroundColor:"#293042" }}
                                type="button"
                                onClick={e => {
                                    if (AddInvValidate()) {
                                        const FilterData = {
                                            All_Invoices,
                                            IsDateWise,
                                            FromDate,
                                            ToDate,
                                        }
                                        let obj = {
                                            AddNew: true,
                                            TrID: 0,
                                            TrData: SelectedMonth,
                                            FilterData,
                                        }
                                        dispatch(setFormData(obj))
                                        document.getElementById("_Link").click();
                                    }
                                }}
                                className="ThemeHeader popup-button"
                            > <Add /> </button>
                            <Link
                                id="_Link"
                                to={"/PurchaseInvoice"}
                            />
                        </div>
                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        <div className="col-md-3 col-lg-3 col-sm-3">
                            <Autocomplete
                                id="disable-clearable"
                                disableClearable
                                options={MonthsList}
                                getOptionLabel={(option) => option.title}
                                value={SelectedMonth}
                                onChange={(e, value) => {
                                    fetchData(TrType, value.value, IsDateWise ? 1 : 0, FromDate, ToDate, All_Invoices ? 1 : 0)
                                    setSelectedMonth(value)
                                    setErrorMsg({ ...ErrorMsg, MonthError: '' })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Month" variant="standard" />
                                )}
                                fullWidth
                            />
                            <span style={{ color: "red" }}>{ErrorMsg.MonthError}</span>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-3">
                            <FormGroup style={{ marginLeft: "20px", marginTop: "14px" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={IsDateWise}
                                            onChange={e => {
                                                fetchData(TrType, SelectedMonth.value, e.target.checked ? 1 : 0, FromDate, ToDate, All_Invoices ? 1 : 0)
                                                setIsDateWise(e.target.checked)
                                            }}
                                            style={{ color: "#293042" }} // Set the background color of the checkbox
                                        />
                                    }
                                    label="Date Wise"
                                />
                            </FormGroup>
                        </div>

                        {/* <div className="col-md-3 col-lg-3 col-sm-3">
                            <FormGroup style={{ marginLeft: "20px", marginTop: "14px" }}>
                                <FormControlLabel control={<Switch checked={IsDateWise}
                                    onChange={e => {
                                        fetchData(TrType, SelectedMonth.value, e.target.checked ? 1 : 0, FromDate, ToDate)
                                        setIsDateWise(e.target.checked)
                                    }}
                                />
                                }
                                    label="Date Wise"
                                />
                            </FormGroup>
                        </div> */}
                        {IsDateWise &&
                            <>
                                <div className="col-md-3 col-lg-3 col-sm-3">
                                    <TextField
                                        name="FromDate"
                                        id="FromDate"
                                        label="From Date"
                                        type="date"
                                        value={FromDate}
                                        autoFocus
                                        onChange={e => {
                                            fetchData(TrType, SelectedMonth.value, IsDateWise ? 1 : 0, e.target.value, ToDate, All_Invoices ? 1 : 0)
                                            setFromDate(e.target.value)
                                            // setErrorMsg({ ...ErrorMsg, NameError: '' })
                                        }}
                                        fullWidth

                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-3">
                                    <TextField
                                        name="ToDate"
                                        id="ToDate"
                                        label="To Date"
                                        type="date"
                                        value={ToDate}
                                        autoFocus
                                        onChange={e => {
                                            fetchData(TrType, SelectedMonth.value, IsDateWise ? 1 : 0, FromDate, e.target.value, All_Invoices ? 1 : 0)
                                            setToDate(e.target.value)
                                            // setErrorMsg({ ...ErrorMsg, NameError: '' })
                                        }}
                                        fullWidth

                                    />
                                </div>
                            </>
                        }

                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        <div className="col-md-2 col-lg-2 col-sm-2">
                            <TextField
                                name="TotalInvoices"
                                id="TotalInvoices"
                                label="Total Invoices"
                                value={TotalInvoices}
                                autoFocus
                                fullWidth
                                inputProps={{ readOnly: true }}

                            />
                            <span style={{ color: "red" }}>{ErrorMsg.TypeError}</span>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-3">
                            <TextField
                                name="TotalInvoicesAmt"
                                id="TotalInvoicesAmt"
                                label="Total Invoices Amount"
                                value={TotalInvoicesAmt}
                                autoFocus
                                inputProps={{ readOnly: true }}
                                fullWidth

                            />
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2">
                            <TextField
                                name="UnpostedCount"
                                id="UnpostedCount"
                                label="Unposted Invoices"
                                value={UnpostedCount}
                                autoFocus
                                inputProps={{ readOnly: true }}
                                fullWidth

                            />
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-3">
                            <FormGroup style={{ marginLeft: "20px", marginTop: "14px" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={All_Invoices}
                                            onChange={e => {
                                                setLoading(true)
                                                fetchData(TrType, SelectedMonth.value, IsDateWise, FromDate, ToDate, e.target.checked ? 1 : 0)
                                                setAll_Invoices(e.target.checked)
                                            }}
                                            style={{ color: "#293042" }} // Set the background color of the checkbox
                                        />
                                    }
                                    label="Show All Invoices"
                                />
                            </FormGroup>
                        </div>

                        {/* <div className="col-md-3 col-lg-3 col-sm-3">
                            <FormGroup style={{ marginLeft: "20px", marginTop: "14px" }}>
                                <FormControlLabel control={<Switch checked={All_Invoices}
                                    onChange={e => {
                                        fetchData(TrType, SelectedMonth.value, IsDateWise, FromDate, ToDate, e.target.checked ? 1 : 0)
                                        setAll_Invoices(e.target.checked)
                                    }}
                                />
                                }
                                    label="Show All Invoices"
                                />
                            </FormGroup>
                        </div> */}
                    </div>
                    <div className='row' style={{ paddingTop: '1%' }}>
                        <div className="col-md-12 col-lg-12 col-sm-12">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    style={{ size: "200px" }}
                                    data={InvoicesList}
                                    columns={columns}
                                    options={{
                                        selectableRows: false,
                                        search: true,
                                        download: false,
                                        print: false,
                                        viewColumns: false,
                                        filter: false,
                                        selectableRows: false,
                                        selection: true,
                                        toolbar: false,
                                        paging: false,
                                        headerStyle: {
                                            fontSize: 12
                                        },
                                    }
                                    }
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                    <GlobalLoader
                        open={loading}
                    />
                </div>
            </div>
        </div>
        ,
        document.getElementById('content')
    )
}

export default PurchaseSale