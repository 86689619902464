import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Edit, AccessibilityNew, Add, Delete, Print } from "@material-ui/icons";
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ItemsModal from './ItemsModal'
// import { SelectPartyData } from './PartiesSlices'
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Autocomplete from '@mui/material/Autocomplete';
import { SelectItemCat } from "../../Services/ItemCatAPI"
import { SelectItems } from "../../Services/ItemAPI"
import { separateNumber } from './../ThousandSeparator'

function ItemsList() {
    const dispatch = useDispatch()
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const UserType = useSelector(({ login }) => login?.user[0]?.UserType)
    document.title = "Stock Keeper / Items"
    const [_ItemsList, set_ItemsList] = useState([])
    const [loading, setLoading] = useState(false)
    const [Open, setOpen] = useState(false)
    const [ItemID, setItemID] = useState(0)
    const [Item_Name, setItem_Name] = useState('')    
    const [ItemCode, setItemCode] = useState('')
    const [Stk_Qty, setStk_Qty] = useState('')
    const [Buffer_Stk, setBuffer_Stk] = useState('')
    const [Item_dept, setItem_dept] = useState('')
    const [Item_cat, setItem_cat] = useState('')
    const [Is_active, setIs_active] = useState(true)
    const [Rate, setRate] = useState('')
    const [Addrress, setAddrress] = useState('')
    const [CatList, setCatList] = useState([])
    const [SelectedCat, setSelectedCat] = useState(null)
    const [ErrorMsg, setErrorMsg] = useState({ TypeError: '' })
    useEffect(() => {
        setLoading(true)
        fetchCat()
    }, [])
    // const validate = () => {
    //     if (!SelectedCat || SelectedCat?.CatID === 0) {
    //         setErrorMsg({ TypeError: 'Category Required' })
    //         document.getElementById('disable-clearable').focus()
    //         return false;
    //     }
    //     setErrorMsg({ TypeError: '' })
    //     return true;
    // }
    const showModal = () => {
        // if (validate()) {
            setOpen(true)
        // }

    }
    const closeModal = () => {
        setOpen(false)
    }
    async function fetchCat() {
        const response = await SelectItemCat(CompID)
        const obj = {
            CatName: "Select All",
            CatID: 0
        }
        response.data.Data.splice(0, 0, obj)
        if (response.data.Data.length > 0) {
            setSelectedCat(response.data.Data[0])
            fetchData(response.data.Data[0].CatID)
        }
        setCatList(response.data.Data)
        setLoading(false)
    }
    async function fetchData(CatID) {
        const response = await SelectItems(CompID, CatID, 0)
        set_ItemsList(response.data.Data)
        setLoading(false)
    }

    // const handleDelete = (CatID) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: 'You will not be able to recover this data!',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, Delete This Item Cat!'
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             const response = await DeleteItemCat(CatID)
    //             if (response.status === 200) {
    //                 if (response.data.status) {
    //                     Swal.fire(

    //                         'Deleted!',
    //                         'Your ItemCat has been deleted.',
    //                         'success'
    //                     );
    //                     fetchData();
    //                 }
    //                 else {
    //                     Swal.fire(

    //                         'OOPPSS!',
    //                         response.data.message,
    //                         'info'
    //                     );
    //                     fetchData();
    //                 }

    //             }

    //         }
    //     });
    // };

    const columns = [
        // {
        //     name: "row_number",
        //     label: "#",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customHeadLabelRender: (columnMeta) => {
        //             return (
        //                 <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
        //             )
        //         },
        //     }
        // },
        {
            name: "ItemCode",
            label: "Code",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "ItemName",
            label: "Item Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "CatName",
            label: "Cat Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "PartyName",
            label: "Department",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "StockQty",
            label: "Stock Qty",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "BufferStock",
            label: "Minimum Stock",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Rate",
            label: "Rate",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const rate = _ItemsList[dataIndex]?.Rate;
                    const formattedrate = rate ? separateNumber(parseFloat(rate).toFixed(2)) : "";
                    return <div>{formattedrate}</div>;
                },
            }
        },
        {
            name: "IsActive",
            label: "IsActive",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>

                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Checkbox
                            style={{ color: '#293042' }}
                            checked={value}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    )
                },

            }
        },

        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (_ItemsList[dataIndex] != null)
                        return (
                            <>
                                {UserType === 'Admin' ? (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            setItemID(_ItemsList[dataIndex].ItemID)
                                            setItem_Name(_ItemsList[dataIndex].ItemName)
                                            setItemCode(_ItemsList[dataIndex].ItemCode)
                                            setStk_Qty(_ItemsList[dataIndex].StockQty)
                                            setBuffer_Stk(_ItemsList[dataIndex].BufferStock)
                                            setItem_dept(_ItemsList[dataIndex].PartyID)
                                            setItem_cat(_ItemsList[dataIndex].CatID)
                                            setRate(_ItemsList[dataIndex].Rate)
                                            setIs_active(_ItemsList[dataIndex].IsActive)
                                            showModal()
                                        }}
                                    >
                                        <Edit className="IconsColor popup-button" />
                                    </Link>
                                ) : (
                                    <Link
                                        to={"#"}
                                        onClick={e => {
                                            Swal.fire(
                                                'OOPS!',
                                                `Only Admin can Edit This Item!`,
                                                'Info'
                                            )
                                        }}
                                    >
                                        <Edit className="IconsColor popup-button" />
                                    </Link>
                                )
                                }
                            </>
                        );
                }
            }
        },
    ];

    return ReactDom.createPortal(
        <div className="col-md-12">
            <ToastContainer />
            <div class="card" >
                <div class="card-body">
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <h1>Items</h1>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: 'right' }} >
                            {UserType === 'Admin' ? (
                                <button
                                    // style={{ backgroundColor:"#293042" }}
                                    type="button"
                                    onClick={e => {
                                        setItemID(0)
                                        showModal()
                                    }}
                                    className="ThemeHeader popup-button"
                                > <Add /> </button>
                            ) : (
                                <button
                                    // style={{ backgroundColor:"#293042" }}
                                    type="button"
                                    onClick={e => {
                                        Swal.fire(
                                            'OOPS!',
                                            `Only Admin can Add New Item!`,
                                            'Info'
                                        )
                                    }}
                                    className="ThemeHeader popup-button"
                                > <Add /> </button>
                            )}
                            {/* &nbsp;&nbsp;&nbsp;
                                <button
                                    style={{ backgroundColor:"#293042" }}
                                    type="button"
                                    onClick={e => {
                                        var mtoken = sessionStorage.getItem('token')
                                        localStorage.setItem('token', sessionStorage.getItem('token'))
                                        var obj = {
                                            SelectedCategory: SelectedCat.CatName,
                                            Grid_Data: _ItemsList,
                                        }
                                        localStorage.setItem("ItemsListReportData", JSON.stringify(obj))
                                        window.open("/ItemsReport", "_blank")
                                        showLoader()
                                    }}
                                    className="ThemeHeader"
                                > <Print /> </button> */}
                        </div>


                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        <div className="col-md-4 col-lg-4 col-sm-4">
                            <Autocomplete
                                id="disable-clearable"
                                disableClearable
                                options={CatList}
                                getOptionLabel={(option) => option.CatName}
                                value={SelectedCat}
                                onChange={(e, value) => {
                                    setSelectedCat(value)
                                    setLoading(true)
                                    fetchData(value.CatID)
                                    setErrorMsg({ ...ErrorMsg, TypeError: '' })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Item Category" variant="standard" />
                                )}
                                fullWidth
                            />
                            {/* <span style={{ color: "red" }}>{ErrorMsg.TypeError}</span> */}
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: '1%' }}>
                        <div className="col-md-12 col-lg-12 col-sm-12">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    style={{ size: "200px" }}
                                    data={_ItemsList}
                                    columns={columns}
                                    options={{
                                        selectableRows: false,
                                        search: true,
                                        download: false,
                                        print: false,
                                        viewColumns: false,
                                        filter: true,
                                        selectableRows: false,
                                        selection: true,
                                        toolbar: false,
                                        paging: false,
                                        headerStyle: {
                                            fontSize: 12
                                        },
                                    }
                                    }
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                    <GlobalLoader
                        open={loading}
                    />
                    <ItemsModal
                        handleClose={closeModal}
                        open={Open}
                        ItemID={ItemID}
                        fetchData={fetchData}
                        SelectedCat={SelectedCat}
                        itemname={Item_Name}
                        stockqty={Stk_Qty}
                        buffstk={Buffer_Stk}
                        rate={Rate}
                        isactive={Is_active}
                        itemdept={Item_dept}
                        itemcat={Item_cat}
                        itemcode={ItemCode} 

                    />
                </div>
            </div>
        </div>
        ,
        document.getElementById('content')
    )
}

export default ItemsList