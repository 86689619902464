import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Checkbox, Card } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import { Save, Close, Print } from "@material-ui/icons";
import { SaveParty } from '../../Services/PartiesAPI'
import { Row, Col } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { getMuiTheme, getCurrentDate } from '../../Services/Common'


const PurchaseSaleReport_Modal = (props) => {
    const { open, handleClose, Modal_Title, fetchData, GroupID, SelectedGroup, reload, setReload, partyname, phno, email, addr, isactive } = props;
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const [FromDate, setFromDate] = useState(getFirstDayOfCurrentMonth())
    const [ToDate, setToDate] = useState(getCurrentDate)
    const [ErrorMsg, setErrorMsg] = useState({ NameError: '', PhError: '', EmailError: '', AddressError: '' })

    useEffect(() => {
    }, [open])

    const handleSubmit = async () => {

        handleClose()

    }

    function getFirstDayOfCurrentMonth() {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const year = firstDayOfMonth.getFullYear();
        const month = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayOfMonth.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="ThemeHeader">
                <h style={{ textAlign: "left" }}>{Modal_Title}</h>
            </DialogTitle>
            <DialogContent>
                <Row style={{ marginTop: "15px", fontWeight: "bold" }}>
                    <Col md={6} sm={6} xl={6} lg={6} style={{ fontSize: "16px" }}>
                        <TextField
                            name="FromDate"
                            id="FromDate"
                            label="From Date"
                            type="date"
                            value={FromDate}
                            autoFocus
                            onChange={e => {
                                // fetchData(TrType, SelectedMonth.value, IsDateWise ? 1 : 0, e.target.value, ToDate)
                                setFromDate(e.target.value)
                                // setErrorMsg({ ...ErrorMsg, NameError: '' })
                            }}
                            fullWidth

                        />
                    </Col>
                    <Col md={6} sm={6} xl={6} lg={6}>
                        <TextField
                            name="ToDate"
                            id="ToDate"
                            label="To Date"
                            type="date"
                            value={ToDate}
                            autoFocus
                            onChange={e => {
                                // fetchData(TrType, SelectedMonth.value, IsDateWise ? 1 : 0, FromDate, e.target.value)
                                setToDate(e.target.value)
                                // setErrorMsg({ ...ErrorMsg, NameError: '' })
                            }}
                            fullWidth

                        />
                    </Col>
                </Row>
            </DialogContent>
            <DialogActions>
                <button className="ThemeHeader popup-button" autoFocus
                    onClick={e => {
                        var mtoken = sessionStorage.getItem('token')
                        localStorage.setItem('token', sessionStorage.getItem('token'))
                        var obj = {
                            From_Date: FromDate,
                            To_Date: ToDate,
                            Modal_Title:Modal_Title,
                        }
                        localStorage.setItem("PurSale_ReportData", JSON.stringify(obj))
                        window.open("/PurchaseSale_Report", "_blank")
                    }}
                >
                    <Print />
                </button>
                <button className="ThemeHeader popup-button" onClick={handleClose}><Close /></button>
            </DialogActions>
        </Dialog>
    )
}

export default PurchaseSaleReport_Modal