import { combineReducers } from "redux"
// import storage from 'redux-persist/lib/storage'
import login from "../components/Login/LoginSlice"
import UsersSlice from "../components/Security/UsersSlice"
import GroupsSlice from "../components/Groups/GroupsSlices"
import ItemCatSlices from "../components/ItemCategory/ItemCatSlices"
import PartiesSlices from "../components/Parties/PartiesSlices"
import TransactionSlice from "../components/PurchaseSale/TransactionSlice"
const createRootReducer = (asyncReducers) => {
    const appReducer = combineReducers({
        login,
        UsersSlice,
        GroupsSlice,
        ItemCatSlices,
        PartiesSlices,
        TransactionSlice,
        ...asyncReducers
    })

    return (state, action) => {
        if (action.type === 'login/userLoggedOut') {
            state = undefined;
            // storage.removeItem('persist:root')
        }

        return appReducer(state, action)
    }
}

export default createRootReducer