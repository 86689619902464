import { createMuiTheme } from "@material-ui/core/styles";
// for local
// export const REACT_BASE_URL = "http://localhost:1330/api"
// for live
export const REACT_BASE_URL = "https://uaqpaperstock.com/api"



export const show_hidden_divs = () => {
    document.getElementById('sidebar_menu').style.display = '';
    document.getElementById('topbar').style.display = '';
    document.getElementById('content').style.display = '';
    document.getElementById('sidebar').style.display = '';
    document.getElementById('wrapper').style.display = '';
}

export const hide_divs = () => {
    document.getElementById('sidebar_menu').style.display = 'none';
    document.getElementById('topbar').style.display = 'none';
    document.getElementById('content').style.display = 'none';
    document.getElementById('sidebar').style.display = 'none';
    document.getElementById('wrapper').style.display = 'none';
}

export const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            MuiTableCell: {
                root: {
                    paddingTop: 5,
                    paddingBottom: 5,
                    fontSize: '18px'

                },
            },
            MUIDataTableBodyCell: {
                root: {
                    fontSize: "14px", // Set your desired font size here
                },
            },
        }
    });


export const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
