import { REACT_BASE_URL } from "./Common"
import axios from "axios"

export const getRolesList = async () => {
  let token = localStorage.getItem('token')
  var config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${REACT_BASE_URL}/squills/Roles/get_roles_list`,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios(config)
  return response;
}

export const getRoleModules = async (RoleId) => {
  let body = JSON.stringify({ RoleId: RoleId })
  let token = localStorage.getItem('token')
  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${REACT_BASE_URL}/squills/Roles/get_role_modules`,
    data: body,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios(config)
  return response;
}

export const saveRoleModules = async (data) => {
  let token = localStorage.getItem('token')
  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${REACT_BASE_URL}/squills/Roles/save_role_modules`,
    data: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios(config)
  return response;
}

export const getRoleScreens = async ({RoleId, ModuleId}) => {
  let token = localStorage.getItem('token')
  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${REACT_BASE_URL}/squills/Roles/get_role_screens`,
    data: JSON.stringify({RoleId: RoleId, ModuleId: ModuleId}),
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios(config)
  return response;
}

export const saveRoleScreens = async (data) => {
  let token = localStorage.getItem('token')
  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${REACT_BASE_URL}/squills/Roles/save_role_screens`,
    data: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios(config)
  return response;
}

export const saveRole = async (data) => {
  let token = localStorage.getItem('token')
  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${REACT_BASE_URL}/squills/Roles/save_role`,
    data: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios(config)
  return response;
}

export const getUsersList = async () => {
  let token = localStorage.getItem('token')
  var config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${REACT_BASE_URL}/squills/Users/get_users_list`,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios(config)
  return response;
}

export const saveUser = async (data) => {
  let token = localStorage.getItem('token')
  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${REACT_BASE_URL}/squills/Users/save_user`,
    data: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios(config)
  return response;
}

export const getUser = async (UserId) => {
  let token = localStorage.getItem('token')
  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${REACT_BASE_URL}/squills/Users/get_user`,
    data: JSON.stringify({UserId: UserId}),
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  const response = await axios(config)
  return response;
}