import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Checkbox, Card } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import { Save, Close, Print } from "@material-ui/icons";
import { SaveParty } from '../../Services/PartiesAPI'
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { getMuiTheme, getCurrentDate } from '../../Services/Common'
import { SelectItemCat } from "../../Services/ItemCatAPI"
import { SelectItems } from "../../Services/ItemAPI"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { CSVLink, CSVDownload } from "react-csv"
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ItemsListModal = (props) => {
    const { open, handleClose } = props;
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const [_ItemsList, set_ItemsList] = useState([])
    const [loading, setLoading] = useState(false)
    const [CatList, setCatList] = useState([])
    const [SelectedCat, setSelectedCat] = useState(null)
    const [ErrorMsg, setErrorMsg] = useState({ NameError: '', PhError: '', EmailError: '', AddressError: '' })

    useEffect(() => {
        fetchCat()
    }, [open])

    const handleSubmit = async () => {

        handleClose()

    }
    async function fetchCat() {
        const response = await SelectItemCat(CompID)
        const obj = {
            CatName: "Select All",
            CatID: 0
        }
        response.data.Data.splice(0, 0, obj)
        if (response.data.Data.length > 0) {
            setSelectedCat(response.data.Data[0])
            fetchData(response.data.Data[0].CatID)
        }
        setCatList(response.data.Data)
    }
    async function fetchData(CatID) {
        const response = await SelectItems(CompID, CatID, 0)
        set_ItemsList(response.data.Data)
        setLoading(false)
    }

    const headers = [
        { label: 'ItemName', key: 'ItemName' },
        { label: 'CatName', key: 'CatName' },
        { label: 'DeptName', key: 'PartyName' },
        { label: 'StockQty', key: 'StockQty' }
    ];

    const renderTooltip_ItemList_CSV = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Download CSV
        </Tooltip>
    );
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="ThemeHeader">
                <h style={{ textAlign: "left" }}>Items List Report</h>
            </DialogTitle>
            <DialogContent>
                <Row style={{ marginTop: "15px", fontWeight: "bold" }}>
                    <Autocomplete
                        id="disable-clearable"
                        disableClearable
                        options={CatList}
                        getOptionLabel={(option) => option.CatName}
                        value={SelectedCat}
                        onChange={(e, value) => {
                            setSelectedCat(value)
                            fetchData(value.CatID)
                            setErrorMsg({ ...ErrorMsg, TypeError: '' })
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Select Item Category" variant="standard" />
                        )}
                        fullWidth
                    />
                </Row>
            </DialogContent>
            <DialogActions>
                <CSVLink filename={`ItemList.csv`} data={_ItemsList} headers={headers} className="popup-button" >
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip_ItemList_CSV}

                    >
                        <FileDownloadIcon style={{ marginBottom: "0.5%", fontSize: "35px" }} />
                    </OverlayTrigger>
                </CSVLink>
                &nbsp;&nbsp;&nbsp;
                <button className="ThemeHeader popup-button" autoFocus
                    onClick={e => {
                        var mtoken = sessionStorage.getItem('token')
                        localStorage.setItem('token', sessionStorage.getItem('token'))
                        var obj = {
                            SelectedCategory: SelectedCat.CatName,
                            Grid_Data: _ItemsList,
                        }
                        localStorage.setItem("ItemsListReportData", JSON.stringify(obj))
                        window.open("/ItemsListReport", "_blank")
                        // showLoader()
                    }}
                >
                    <Print />
                </button>
                <button className="ThemeHeader popup-button" onClick={handleClose}><Close /></button>
            </DialogActions>
        </Dialog>
    )
}

export default ItemsListModal